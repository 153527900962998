<template>
  <section class="pub-hero-split-banner show-on-load">
    <div
      :class="{
        'pub-hero-split-banner__image-wrapper': true,
        'var-dark': hasPubMainImage,
      }"
    >
      <base-image
        v-if="hasPubMainImage"
        :desktop-url="fields?.pubMainImage?.value?.src"
        :desktop-alt="fields?.pubMainImage?.value?.alt"
        fetchpriority="high"
      />
      <img v-else :src="placehoderImage" alt="Main Placeholder" />
      <a
        v-if="hasEnoughGalleryData"
        class="pub-hero-split-banner__gallery-icon"
        aria-label="Gallery icon"
        :href="`#${popupFields.popupID.value}`"
      >
        <i class="icon icon-Image" aria-hidden="true"></i>
      </a>
    </div>

    <div class="pub-hero-split-banner__content-wrapper var-dark">
      <div class="pub-hero-split-banner__headings">
        <sc-text tag="h1" class="pub-hero-split-banner__title" :field="fields?.pubShortName" />
        <sc-text tag="p" class="pub-hero-split-banner__address" :field="fields?.address" />
      </div>
      <div class="pub-hero-split-banner__highlights">
        <h2 class="pub-hero-split-banner__highlights-heading font-m">
          {{ t('pub-hero-split-banner-highlights-header') }}
        </h2>
        <sc-rich-text :field="fields?.highlights" class="rich-text" />
      </div>
      <div v-if="pubInfoItems" class="pub-hero-split-banner__info">
        <div
          v-for="(item, index) in pubInfoItems"
          :key="index"
          class="pub-hero-split-banner__info-item"
        >
          <strong class="pub-hero-split-banner__info-item-label">{{ item.label }}: </strong>
          <div v-if="Array.isArray(item.value)" class="pub-hero-split-banner__info-item--array">
            <span
              v-for="(value, valueIndex) in item.value"
              :key="valueIndex"
              class="pub-hero-split-banner__info-item--array-item"
            >
              {{ value }}
            </span>
          </div>
          <template v-else>
            {{ item.value }}
          </template>
        </div>
      </div>
      <base-button
        v-if="isButtonVisible"
        type="link"
        class="pub-hero-split-banner__cta"
        :link="fields.link.value"
      />
    </div>
  </section>
  <image-gallery-popup v-if="hasEnoughGalleryData && isMounted" v-bind="popupArgs" />
</template>

<script setup>
import { computed, onMounted, ref, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  Text as ScText,
  RichText as ScRichText,
  isEditorActive,
} from '@sitecore-jss/sitecore-jss-vue';
import BaseButton from '../2_elements/BaseButton/BaseButton.vue';
import BaseImage from '../2_elements/BaseImage/BaseImage.vue';
import ImageGalleryPopup from '../ImageGalleryPopup/ImageGalleryPopup.vue';
import placehoderImage from '../../assets/images/pub-partners/split-banner-placeholder.webp';
import { useRegisterEcommerceDataLayerAction } from '../../data-layer/helpers/registerEcommerceDataLayer';
import { usePubDetailsItemUtils } from '../../composables/usePubDetailsItemUtils';

const jssStore = inject('jssStore');
const brandName = jssStore?.sitecoreContext()?.brandName;
const venueId = jssStore?.sitecoreContext()?.venueId;
const segment = jssStore?.sitecoreContext()?.segment;

const MIN_COUNT_OF_SLIDES = 2;

const { t } = useI18n();
const registerEcommercePubDetailsDataLayerAction = useRegisterEcommerceDataLayerAction();
const { createContentItem, transformPercentage, FALLBACK_VALUE } = usePubDetailsItemUtils();

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
});

const isMounted = ref(false);

const popupFields = computed(() => ({
  popupID: {
    value: 'hero-split-banner-gallery-popup',
  },
  title: props.fields?.galleryPopupTitle,
}));

const popupArgs = computed(() => ({
  fields: popupFields.value,
  rendering: {
    componentName: 'ImageGalleryPopup',
    fields: popupFields.value,
    placeholders: {
      'image-gallery-popup-placeholder': [
        {
          componentName: 'GalleryBase',
          fields: { slides: { value: props.fields?.pubImages?.value ?? [] } },
        },
      ],
    },
  },
}));

const hasEnoughGalleryData = computed(
  () => props.fields?.pubImages?.value?.length >= MIN_COUNT_OF_SLIDES
);

const hasPubMainImage = computed(() => !!props.fields?.pubMainImage?.value?.src);

const isButtonVisible = computed(
  () => (!!props.fields?.link?.value?.href && !!props.fields?.link?.value?.text) || isEditorActive()
);

const turnoverShare = computed(() => {
  const value = props.fields?.percentageOfNetTurnover?.value;
  if (value !== undefined) {
    if (['', null].includes(value)) return FALLBACK_VALUE;
    return `${transformPercentage(value)} ${t('pub-hero-split-banner-share')}`;
  }
  return undefined;
});

const turnoverShareFoodDrink = computed(() => {
  let foodValue = props.fields?.percentageOfNetFoodSales?.value;
  let drinkValue = props.fields?.percentageOfNetDrinkSales?.value;
  if (foodValue !== undefined || drinkValue !== undefined) {
    return `${typeof foodValue !== 'number' ? FALLBACK_VALUE : transformPercentage(foodValue)} ${t(
      'pub-hero-split-banner-food-share'
    )}, ${typeof drinkValue !== 'number' ? FALLBACK_VALUE : transformPercentage(drinkValue)} ${t(
      'pub-hero-split-banner-drink-share'
    )}`;
  }
  return undefined;
});

const pubInfoItems = computed(() =>
  [
    {
      key: 'pub-hero-split-banner-franchise-brand',
      value: props.fields?.franchiseBrand?.value,
      transformNumber: false,
    },
    {
      key: 'pub-hero-split-banner-weekly-rent',
      value: props.fields?.weeklyRent?.value,
      transformNumber: true,
    },
    {
      key: 'pub-hero-split-banner-annual-estimated-turnover',
      value: props.fields?.annualTurnover?.value,
      transformNumber: true,
    },
    {
      key: 'pub-hero-split-banner-location',
      value: props.fields?.location?.value,
      transformNumber: false,
    },
    {
      key: 'pub-hero-split-banner-agreement-type',
      value: props.fields?.agreementType?.value,
      transformNumber: false,
    },
    {
      key: 'pub-hero-split-banner-turnover-percentage',
      value: turnoverShare.value,
      transformNumber: false,
    },
    {
      key: 'pub-hero-split-banner-turnover-percentage',
      value: turnoverShareFoodDrink.value,
      transformNumber: false,
    },
    {
      key: 'pub-hero-split-banner-estimated-total-sales',
      value: props.fields?.estimatedTotalSales?.value,
      transformNumber: true,
    },
    {
      key: 'pub-hero-split-banner-estimated-franchisee-retained-income',
      value: props.fields?.estimatedFranchiseeRetainedIncome?.value,
      transformNumber: true,
    },
  ]
    .filter((item) => item.value !== undefined)
    .map((item) =>
      createContentItem(
        item.key,
        item.value,
        item.transformNumber,
        item.isBolded,
        item.isPercentage
      )
    )
);

onMounted(() => {
  isMounted.value = true;

  registerEcommercePubDetailsDataLayerAction({
    event: 'view_item',
    ecommerce: {
      items: [
        {
          item_name: 'Pub_Details',
          item_id: venueId,
          item_brand: brandName,
          item_category: 'book_a_pub',
          item_category2: 'pub',
          _item_category3: props.fields.address?.value,
          item_list_name: 'pub_direct',
          _item_variant: props.fields.pubShortName?.value,
          index: 1,
          location_id: venueId,
          affliation: props.fields.pubShortName?.value,
          quantity: 1,
          house_id: venueId,
          _house: props.fields.pubShortName?.value,
          brand: brandName,
          division: 'local_pubs',
          pub_name: props.fields.pubShortName?.value,
          pub_segment: segment,
          venueId,
          intent: 'pub_search',
        },
      ],
    },
  });
});
</script>

<style lang="scss">
@import './scss/PubHeroSplitBanner.scss';
</style>
