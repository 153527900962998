<template>
  <div class="hero-banner show-on-load" :class="classes">
    <base-image
      :mobile-url="fields?.heroMobile?.value?.src"
      :tablet-url="fields?.heroTablet?.value?.src"
      :desktop-url="fields?.heroDesktop?.value?.src"
      :desktop-alt="fields?.heroDesktop?.value?.alt"
      :tablet-alt="fields?.heroTablet?.value?.alt"
      :mobile-alt="fields?.heroMobile?.value?.alt"
      :full-width="true"
      fetchpriority="high"
    />
    <div v-if="fields?.title?.value || $jss.sitecoreContext().pageEditing" class="container">
      <sc-text :tag="params.headingType ?? 'h1'" :field="fields.title" class="hero-banner__title" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import BaseImage from '../2_elements/BaseImage/BaseImage.vue';
import { Text as ScText } from '@sitecore-jss/sitecore-jss-vue';

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
    default: () => ({}),
  },
});

const classes = computed(() => [
  !!props.params?.backgroundOverlay && 'hero-banner--bg-overlay',
  !!props.params?.dynamicHeight && 'hero-banner--dynamic-height',
]);
</script>

<style lang="scss">
@import './scss/HeroBanner.scss';
</style>
