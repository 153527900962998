<template>
  <nav class="nav-bar">
    <div
      class="nav-bar__top container"
      :class="{ ...detectComponentVariant(variantedComponents.MAIN_HEADER) }"
    >
      <ul class="nav-bar__menu-list">
        <li v-for="(menuItem, index) in menus" :key="index">
          <button
            v-if="menuItem?.fields?.subItems.length > 0"
            ref="menusRefs"
            class="nav-bar__nav-item nav-bar__nav-item--has-subitems"
            :class="{
              'nav-bar__nav-item--selected':
                (selectedMenu === index ||
                  (threeLvl &&
                    selectedMenu === null &&
                    menuItem?.fields?.link?.value?.href === $route.path)) &&
                !isLinkHovered,
              ...navItemClasses,
            }"
            :tabindex="isMenuSelected && selectedMenu !== index ? -1 : 0"
            :aria-expanded="selectedMenu === index"
            :data-test-id="`nav-item-${index}`"
            @mouseover="() => openMegaNav(index)"
            @mouseleave="closeMegaNavWithTimeout"
            @click="() => handleKeyDown(index)"
            @keydown.enter.space.prevent="() => handleKeyDown(index)"
            @keydown.tab.shift.exact="closeMegaNav"
          >
            {{ menuItem?.fields?.title?.value }}
          </button>
          <component
            :is="getLinkTag(menuItem?.fields?.link?.value?.linktype)"
            v-else
            v-bind="getLinkBindings(menuItem?.fields?.link)"
            ref="menusRefs"
            class="nav-bar__nav-item nav-bar__nav-item--link no-underline"
            :class="{
              'nav-bar__nav-item--selected':
                `/${route?.params?.sitecoreRoute?.[0] ?? ''}` ===
                  menuItem?.fields?.link?.value?.href &&
                !isMenuSelected &&
                !isLinkHovered,
              ...navItemClasses,
            }"
            :tabindex="isMenuSelected ? -1 : 0"
            :data-test-id="`nav-item-${index}`"
            @mouseover="isLinkHovered = true"
            @mouseleave="isLinkHovered = false"
            @focus="closeMegaNavWithTimeout"
            @click="handleNavigationClick(menuItem?.fields?.title?.value)"
          >
            {{ menuItem?.fields?.title?.value }}
          </component>
        </li>
      </ul>
      <slot></slot>
    </div>
    <div
      class="nav-bar__mega-nav"
      :class="{
        'nav-bar__mega-nav--visible': isMenuSelected,
        'nav-bar__mega-nav--three-lvl': threeLvl,
      }"
      @mouseover="() => openMegaNav(selectedMenu)"
      @mouseleave="closeMegaNavWithTimeout"
    >
      <transition name="mega-nav-transition" mode="out-in">
        <ul
          v-if="isMenuSelected && !threeLvl"
          :key="selectedMenu"
          class="nav-bar__mega-nav-items container"
          :class="{
            'nav-bar__mega-nav-items--dense': selectedMenuItems.length > 4,
          }"
        >
          <li v-for="(item, index) in selectedMenuItems" :key="index">
            <mega-nav-item
              v-bind="item.fields"
              :data-test-id="`mega-nav-item-${index}`"
              :image-param="getImageParam()"
              @keydown.tab.exact="() => closeMegaNavAfterLastTab(index)"
              @click="onMegaNavClick(item)"
            />
          </li>
        </ul>
        <template v-else>
          <slot
            name="threeLvlNav"
            :selected-menu="selectedMenu"
            :on="{
              navClick: (item) => onMegaNavClick(item),
              tab: (isLastMenuItem) => closeMegaNavAfterLastTab(_, isLastMenuItem),
            }"
          ></slot>
        </template>
      </transition>
    </div>
  </nav>
</template>

<script setup>
import { ref, onBeforeUnmount, computed, watchEffect, inject } from 'vue';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { useRoute } from 'vue-router';
import { useScroll } from '@vueuse/core';
import { getLinkTag, getLinkBindings } from '../../../utils/linkTransformer';
import {
  useDetectVariantByBrandAndTheme,
  variantedComponents,
} from '../../../utils/componentVariants';
import MegaNavItem from './MegaNavItem/MegaNavItem.vue';
import { useAppStore } from '../../../stores/appStore';

const detectComponentVariant = useDetectVariantByBrandAndTheme();
const appStore = useAppStore();
const route = useRoute();

const jssStore = inject('jssStore');
const threeLvl = inject('threeLvl');
const brandName = jssStore?.sitecoreContext()?.brandName;

const emit = defineEmits(['mega-nav-open', 'mega-nav-close']);

const props = defineProps({
  menus: {
    type: Object,
  },
  logoPosition: {
    type: String,
  },
  handleNavigationClick: {
    type: Function,
  },
});

const selectedMenu = ref(null);
const isLinkHovered = ref(false);
const menusRefs = ref([]);
const SCROLL_MAX_OFFSET = 350;
const imageParams = ['', '830', '540', '400', '330', '270'];
let timeout = null;

const { y: scrollOffsetY } = useScroll(isServer() ? null : window);
const isMenuSelected = computed(() => selectedMenu.value != null);
const selectedMenuItems = computed(
  () => isMenuSelected.value && props.menus[selectedMenu.value].fields.subItems
);
const navItemClasses = computed(() => ({
  'font-s': brandName === 'ChefBrewer',
  'nav-bar__nav-item--no-underline': brandName === 'Belhaven',
}));

const getImageParam = () => imageParams[selectedMenuItems.value.length - 1];

const closeMegaNavAfterLastTab = (index, isLastMenuItem = false) => {
  if (!threeLvl ? index === selectedMenuItems.value.length - 1 : isLastMenuItem) {
    menusRefs.value[selectedMenu.value].focus();
    closeMegaNav();
  }
};

const closeMegaNav = () => {
  selectedMenu.value = null;
  emit('mega-nav-close');
};

const openMegaNav = (index) => {
  if (timeout) clearTimeout(timeout);
  selectedMenu.value = index;
  emit('mega-nav-open');
};

const closeMegaNavWithTimeout = () => {
  timeout = setTimeout(closeMegaNav, 500);
};

const handleKeyDown = (index) => {
  if (isMenuSelected.value) closeMegaNav();
  else openMegaNav(index);
};

const onMegaNavClick = (item) => {
  closeMegaNav();
  props.handleNavigationClick(item?.fields?.title?.value, item?.fields?.link?.value?.href || '/');
};

watchEffect(() => {
  if (isMenuSelected.value && scrollOffsetY.value > SCROLL_MAX_OFFSET) closeMegaNav();
});

watchEffect(() => {
  appStore.setBackdrop({ enable: isMenuSelected.value, global: false });
});

onBeforeUnmount(() => {
  if (timeout) {
    clearTimeout(timeout);
  }
  appStore.setBackdrop({ enable: false, global: false });
});

defineExpose({ closeMegaNav });
</script>

<style lang="scss">
@import './scss/NavBar.scss';
</style>
