<!-- Component to use in Experience Editor for Hidden personalization rule -->
<template>
  <div class="hidden-rendering"></div>
</template>

<script>
export default {
  name: 'HiddenRendering',
};
</script>
