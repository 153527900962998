<template>
  <section v-if="fields?.link?.value?.href" class="iframe-item">
    <div class="container">
      <iframe
        :src="fields?.link?.value?.href"
        :title="fields?.title?.value"
        :width="iframeWidth"
        :height="iframeHeight"
        tabindex="-1"
        allow="fullscreen"
        loading="lazy"
      ></iframe>
    </div>
  </section>
  <template v-else-if="$jss.sitecoreContext().pageEditing">
    <div class="container">
      <div class="iframe-item__warning">Iframe link and title is required</div>
    </div>
  </template>
</template>

<script setup>
import { computed } from 'vue';
import useMedia from '../../utils/useMedia';

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const { isMedia: isDesktop } = useMedia('(min-width: 1024px)');

const iframeHeight = computed(() =>
  isDesktop.value ? props.params.desktopHeight : props.params.mobileHeight
);
const iframeWidth = computed(() => (props.params?.width?.length ? props.params.width : '100%'));
</script>

<style lang="scss">
@import './scss/IframeItem.scss';
</style>
