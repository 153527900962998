<template>
  <section class="open-table-widget">
    <div ref="scripts"></div>
  </section>
</template>

<script setup>
import { onMounted, ref } from 'vue';

const scripts = ref(null);

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

onMounted(() => {
  if (!props.fields?.openTableUrl?.value) return;
  const script = document.createElement('script');
  script.src = props.fields.openTableUrl.value;

  scripts.value.appendChild(script);
});
</script>

<style lang="scss">
@import './scss/OpenTableWidget.scss';
</style>
