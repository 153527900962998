<template>
  <section class="sitemap-block">
    <div class="container">
      <ul class="row sitemap-block__list">
        <li
          v-for="(linkPart, linkPartIdx) in links"
          :key="`sitemap-link-${linkPartIdx}`"
          class="sitemap-block-item col-12 col-md-6 col-lg-3"
        >
          <base-link
            v-if="linkPart?.href"
            :field="{
              value: {
                href: linkPart?.href,
                text: linkPart?.title,
                linktype: linkPart?.linktype,
              },
            }"
            :classes="`sitemap-block-item__main-link inverted-underline ${headerFontClass}`"
          />
          <span v-else class="sitemap-block-item__main-link" :class="headerFontClass">
            {{ linkPart?.title }}
          </span>

          <ul v-if="linkPart?.links?.length">
            <li
              v-for="(subLink, subLinkIdx) in linkPart.links"
              :key="`sitemap-link-${linkPartIdx}-${subLinkIdx}`"
              class="sitemap-block-item__list-item"
            >
              <base-link
                v-if="subLink?.href"
                :field="{
                  value: {
                    href: subLink?.href,
                    text: subLink?.title,
                    linktype: subLink?.linktype,
                  },
                }"
                class="sitemap-block-item__sub-link inverted-underline"
              />
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </section>
</template>

<script setup>
import { computed, inject } from 'vue';
import BaseLink from '../2_elements/BaseLink/BaseLink.vue';

const jssStore = inject('jssStore');
const brandName = jssStore?.sitecoreContext()?.brandName || null;

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const headerFontClass = computed(() => (brandName === 'ChefBrewer' ? 'font-s' : 'font-m'));
const links = computed(() => props.fields?.links?.value ?? []);
</script>

<style lang="scss">
@import './scss/SitemapBlock.scss';
</style>
