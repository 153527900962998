<template>
  <div class="mobile-menu-footer">
    <ul class="mobile-menu-footer-list">
      <li v-if="isCallUsButtonAvailable" class="mobile-menu-footer-list__item">
        <a
          data-test-id="link-call"
          class="mobile-menu-footer-list__link no-underline"
          :class="linkClasses"
          :href="getCallUsLink"
        >
          <i class="icon icon-Phone" aria-hidden="true"></i>
          {{ t('call-us') }}
        </a>
      </li>
      <li v-if="isGetDirectionsButtonAvailable" class="mobile-menu-footer-list__item">
        <a
          data-test-id="link-location"
          class="mobile-menu-footer-list__link no-underline"
          :class="linkClasses"
          :href="getDirectionsMapsLink"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="icon icon-Location" aria-hidden="true"></i>
          {{ t('get-directions') }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const jssStore = inject('jssStore');
const brandName = jssStore?.sitecoreContext()?.brandName;

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
});

const isCallUsButtonAvailable = !!props?.fields?.venuePhoneNumber?.value;
const getCallUsLink = computed(() => `tel:${props?.fields?.venuePhoneNumber?.value}`);

const linkClasses = computed(() => ({
  'font-s': ['HungryHorse', 'ChefBrewer'].includes(brandName),
}));

const isGetDirectionsButtonAvailable =
  !!props?.fields?.venueLatitude?.value && !!props?.fields?.venueLongitude?.value;
const getDirectionsMapsLink = computed(() => {
  const latitudeValue = props?.fields?.venueLatitude?.value;
  const longitudeValue = props?.fields?.venueLongitude?.value;

  return `https://www.google.com/maps?q=${latitudeValue},${longitudeValue}`;
});
</script>

<style lang="scss">
@import './scss/MobileMenuFooter.scss';
</style>
