const store = {
  // default state
  state: {
    sitecoreContext: {
      pageEditing: false,
    },
    routeData: null,
  },
  setSitecoreData(sitecoreData) {
    const context = sitecoreData?.sitecore?.context || {};
    const route = sitecoreData?.sitecore?.context?.pageEditing
      ? validateRoute(sitecoreData?.sitecore?.route)
      : sitecoreData?.sitecore?.route;

    // Do not replace the original state object - the store and any components that use the store
    // need to share a reference to the same object in order for mutations to be observed.
    this.state.routeData = route;
    this.state.sitecoreContext = {
      ...context,
      routeName: route?.name,
      itemId: route?.itemId,
    };
  },
};

const ErrorComponent = (validatedComponentName, args) => ({
  componentName: 'Error Component',
  validatedComponentName,
  ...args,
});

const NO_DATASOURCE_REQ_COMPONENTS = [
  'BlogListing',
  'BlogPost',
  'BookSpaceWidget',
  'BookTableWidget',
  'OpenTableWidget',
  'CookieDeclaration',
  'FixedPromoMessage',
  'FormContainer',
  'HeroCarousel',
  'InteractiveMap',
  'OpenTabletWidget',
  'PromoCarousel',
  'PubInfoBar',
  'RegularEventsList',
  'SitemapBlock',
  'SportEventList',
  'SubNavigation',
  'BreadcrumbsBar',
  'PubIntroduction',
  'BusinessDevelopmentManagerCard',
  'ImportantInformationAccordion',
  'BookingWidget',
];

const NO_CHILD_REQ_COMPONENTS = ['SportEventList', 'IntroductionItem'];

const validateRoute = (route) => {
  // Check if route object is a component and has datasource defined
  if (
    route?.componentName &&
    !route.dataSource &&
    !NO_DATASOURCE_REQ_COMPONENTS.includes(route.componentName)
  ) {
    return ErrorComponent(route.componentName, { missingDatasource: true });
  }

  // If the route object has a placeholder defined, check if the placeholder contains at least one valid component.
  // If so, validate the children.
  if (route?.placeholders) {
    const placeholders = Object.fromEntries(
      Object.entries(route?.placeholders).map(([placeholderKey, placeholderItems]) => {
        if (
          !placeholderItems.some((child) => child?.componentName) &&
          !placeholderKey.startsWith('jss-') &&
          !NO_CHILD_REQ_COMPONENTS.includes(route.componentName)
        ) {
          return [
            placeholderKey,
            [
              ...placeholderItems,
              ErrorComponent(route?.componentName ?? placeholderKey, { missingChildren: true }),
            ],
          ];
        }

        return [placeholderKey, placeholderItems.map((child) => validateRoute(child))];
      })
    );

    return { ...route, placeholders };
  }

  return route;
};

// Vue plugins must export a function named 'install'
function install(App) {
  App.config.globalProperties.$jss = {
    // there may be other JSS plugins installed, merge existing properties
    ...(App.config.globalProperties.$jss || {}),
    store,
    sitecoreContext() {
      // this is intended only as a convenience function for easier access to the current context.
      return store.state.sitecoreContext;
    },
    routeData() {
      // this is intended only as a convenience function for easier access to the current routeData.
      return store.state.routeData;
    },
  };

  App.provide('jssStore', App.config.globalProperties.$jss);
}

export default { install };
