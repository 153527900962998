<template>
  <section class="mib show-on-load" :class="classes">
    <div :class="{ container: !params.fullWidth }">
      <div :class="{ row: !params.fullWidth }">
        <div class="col-12">
          <div class="mib__container">
            <div class="mib__background">
              <base-image
                class="mib__picture"
                :mobile-url="fields?.imageMobile?.value?.src"
                :tablet-url="fields?.imageTablet?.value?.src"
                :desktop-url="fields?.imageDesktop?.value?.src"
                :mobile-alt="fields?.imageMobile?.value?.alt"
                :tablet-alt="fields?.imageTablet?.value?.alt"
                :desktop-alt="fields?.imageDesktop?.value?.alt"
                :full-width="!!params.fullWidth"
                :lazy-loading="componentRouteIndex > 1 ? 'lazy' : null"
                :fetchpriority="componentRouteIndex > 1 ? 'low' : 'high'"
                :preload-image-name="`MultipurposeImageBanner--${params.backgroundType}`"
              />
            </div>
            <div class="mib__text">
              <sc-text
                class="mib__heading"
                :field="fields?.heading"
                :tag="params.headingType ?? 'h2'"
              />
              <sc-text
                class="mib__subheading"
                :class="[brandName === 'Belhaven' && 'font-s']"
                :field="fields?.subheading"
                tag="span"
              />
              <sc-rich-text class="mib__desc" :field="fields?.description" tag="p" />
              <slot name="description" />
              <div
                v-if="
                  fields?.firstLink?.value?.text ||
                  fields?.secondLink?.value?.text ||
                  ($jss.sitecoreContext().pageEditing && rendering?.dataSource)
                "
                class="mib__buttons"
              >
                <base-button
                  v-if="fields?.firstLink?.value?.text || $jss.sitecoreContext().pageEditing"
                  :link="fields?.firstLink?.value"
                  type="link"
                  data-test-id="mib-first-link"
                  @click="
                    () =>
                      handleClickButton(fields?.firstLink?.value?.text, fields?.firstLink?.value)
                  "
                />
                <base-button
                  v-if="fields?.secondLink?.value?.text || $jss.sitecoreContext().pageEditing"
                  :link="fields?.secondLink?.value"
                  type="link"
                  variant="secondary"
                  data-test-id="mib-second-link"
                  @click="
                    () =>
                      handleClickButton(fields?.secondLink?.value?.text, fields?.secondLink?.value)
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, inject } from 'vue';
import { Text as ScText, RichText as ScRichText } from '@sitecore-jss/sitecore-jss-vue';
import BaseImage from '../2_elements/BaseImage/BaseImage.vue';
import BaseButton from '../2_elements/BaseButton/BaseButton.vue';
import { useRegisterDataLayerAction } from '../../data-layer/helpers/registerDataLayer';
import { useRouteData } from '../../composables/useRouteData';

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
  dataLayerFn: {
    type: Function,
    default: null,
  },
});

const jssStore = inject('jssStore');
const brandName = jssStore?.sitecoreContext()?.brandName;
const { componentRouteIndex } = useRouteData(props.rendering?.uid);

const isVarDark = computed(
  () =>
    props.params.backgroundType === 'full' ||
    (['HungryHorse', 'ChefBrewer', 'Belhaven', 'PubPartners'].includes(brandName) &&
      !!props.params.alternativeBackground &&
      props.params.backgroundType === 'split')
);

const classes = computed(() => [
  !!props.params.fullWidth && 'mib--full-width',
  !!props.params.backgroundOverlay && 'mib--bg-overlay',
  !!props.params.marginBottom && 'mib--margin-btm',
  !!props.params.textPosition && `mib--text-${props.params.textPosition}`,
  !!props.params.mobileTextPosition && `mib--text-mob-${props.params.mobileTextPosition}`,
  !!props.params.backgroundType && `mib--bg-${props.params.backgroundType}`,
  !!props.params.alternativeBackground && 'mib--alternative-background',
  !!props.params.topSubheading && 'mib--top-subheading',
  isVarDark.value && 'var-dark',
  brandName === 'ChefBrewer' && 'bottom-green-border top-green-border',
  brandName === 'Belhaven' && !!props.params.fullWidth && 'blh-full-width',
]);

const registerDataLayerAction = useRegisterDataLayerAction();

const handleClickButton = (action, href) => {
  if (props.dataLayerFn) {
    props.dataLayerFn(action, href);
    return;
  }
  registerDataLayerAction({
    event_name: 'click_link',
    link_text: action,
    intent: action,
    link_destination: href,
    event_location: 'body',
    component: 'multipurpose_image_banner',
  });
};
</script>

<style lang="scss">
@import './scss/MultipurposeImageBanner.scss';
</style>
