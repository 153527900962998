<template>
  <div
    v-if="isModalOpen || $jss.sitecoreContext().pageEditing"
    ref="modal"
    :class="[
      'image-gallery-popup',
      $jss.sitecoreContext().pageEditing && 'image-gallery-popup--editor',
    ]"
    @keydown.esc="closeModal"
  >
    <div class="container image-gallery-popup__container">
      <div
        class="image-gallery-popup__modal"
        :aria-label="(fields.title?.value || 'Image gallery') + ' - dialog'"
        role="dialog"
        aria-modal="true"
      >
        <div class="image-gallery-popup__header">
          <sc-text :field="fields.title" tag="h2" class="font-m image-gallery-popup__heading" />
          <button class="image-gallery-popup__close" :aria-label="t('close')" @click="closeModal">
            <i class="icon icon-Close" aria-hidden="true"></i>
          </button>
        </div>
        <div class="image-gallery-popup__content">
          <sc-placeholder name="image-gallery-popup-placeholder" :rendering="rendering" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { Placeholder as ScPlaceholder, Text as ScText } from '@sitecore-jss/sitecore-jss-vue';
import { useI18n } from 'vue-i18n';
import { useRegisterDataLayerAction } from '../../data-layer/helpers/registerDataLayer';
import { usePopupModal } from '../../composables/usePopupModal';

const { t } = useI18n();

defineEmits(['click']);

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const modal = ref(null);

const registerDataLayerAction = useRegisterDataLayerAction();

const handleRegisterDataLayerGallery = (event) => {
  registerDataLayerAction({
    event_name: event,
    component: 'gallery_base',
  });
};

const onModalClose = () => {
  handleRegisterDataLayerGallery('close_gallery');
};

const onModalOpen = () => {
  handleRegisterDataLayerGallery('open_gallery');
};

const { closeModal, isModalOpen } = usePopupModal({
  popupID: props.fields.popupID.value,
  modalRef: modal,
});

watch(isModalOpen, (newValue) => {
  newValue ? onModalOpen() : onModalClose();
});
</script>

<style lang="scss">
@import './scss/ImageGalleryPopup.scss';
</style>
