<template>
  <main id="content" class="site-wrapper site-wrapper--main" tabindex="-1">
    <placeholder name="jss-main" :rendering="route" />
  </main>
</template>

<script setup>
import { onMounted, onBeforeUnmount, inject, computed } from 'vue';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import { useHead } from '@unhead/vue';
import { enableA11yFocus, disableA11yFocus } from './utils/a11y-focus';
import { useRouteData } from './composables/useRouteData';
import { useSources } from './composables/useSources';
import './scss/app.scss';

const props = defineProps({
  route: {
    type: Object,
    default: () => ({}),
  },
});

const jssStore = inject('jssStore');
const sitecoreContext = jssStore?.sitecoreContext() || {};
const brandName = sitecoreContext.brandName || null;
const pubSegmentName = sitecoreContext.segment || null;
const favicon = sitecoreContext.favicon || null;
const pageScripts = sitecoreContext.pageScripts || [];
const venueOrigin = sitecoreContext.venueOrigin ?? '';
const itemPath = sitecoreContext.itemPath ?? '';
const canonicalUrl = sitecoreContext.canonicalUrl;
const metaRobots = sitecoreContext.metaRobots || '';
const schemas = sitecoreContext.schemas || '';
const venueName = sitecoreContext.venueName || '';
const venueId = sitecoreContext.venueId || '';
const isSports = sitecoreContext.sports || false;
const isContentCards = sitecoreContext.enableContentCard || false;
const routeName = sitecoreContext.routeName || '';
const { topMainComponentData } = useRouteData();

import(/* webpackChunkName: "[request]" */ `./brands/${brandName}/_${brandName}.scss`);

const schemaObjects = computed(() => {
  return schemas.map((schema) => {
    return { type: 'application/ld+json', innerHTML: schema };
  });
});

const fullUrl = computed(() =>
  !isServer() ? window.location.href.split('?')[0] : venueOrigin + itemPath
);

const pageScriptsHead = pageScripts
  .filter((s) => s.placement === 'head')
  .map((s) => {
    return {
      tag: s.tag,
      ...s.attributes,
      ...(s['innerHtml'] && { innerHTML: s['innerHtml'] }),
    };
  });

const headPageScripts = pageScriptsHead
  .filter((s) => s.tag === 'script')
  .map(({ tag, ...attr }) => attr);
const headPageNoScripts = pageScriptsHead
  .filter((s) => s.tag === 'noscript')
  .map(({ tag, ...attr }) => attr);

const pageScriptsBody = pageScripts
  .filter((s) => s.placement !== 'head')
  .map((s, i) => {
    return {
      tag: s.tag,
      key: `pageScriptsBody-${i}`,
      ...s.attributes,
      ...(s['innerHtml'] && { innerHTML: s['innerHtml'] }),
      tagPosition: s.placement === 'pbody' ? 'bodyOpen' : 'bodyClose',
    };
  });

const bodyPageScripts = pageScriptsBody
  .filter((s) => s.tag === 'script')
  .map(({ tag, ...attr }) => attr);
const bodyPageNoScripts = pageScriptsBody
  .filter((s) => s.tag === 'noscript')
  .map(({ tag, ...attr }) => attr);

const gtmLink = computed(() => {
  const gtmId = /GTM-\w+/.exec(
    pageScripts.find((s) => /GTM-\w+/.test(s['innerHtml']))?.['innerHtml']
  )?.[0];

  if (!gtmId) return [];

  return [
    {
      rel: 'preload',
      href: `https://www.googletagmanager.com/gtm.js?id=${gtmId}`,
      as: 'script',
    },
  ];
});

const safeEscapeBackticks = (str) => str?.replaceAll('`', '\\`') ?? '';

const headScripts = computed(() =>
  isServer()
    ? [
        {
          innerHTML: `
            window.dataLayer = [{
              'pubBrandName' : '${brandName}',
              'pubSegmentName': \`${safeEscapeBackticks(pubSegmentName)}\`,
              'pubName': \`${safeEscapeBackticks(venueName)}-${venueId}\`,
              'pageCategory': '${routeName}',
              'fullUrl': '${fullUrl.value}',
              'userID' : '',
              'Sports' : '${isSports ? 'Yes' : 'No'}',
              'contentCards' : '${isContentCards ? 'Yes' : 'No'}',
            }];
            `,
        },
        {
          innerHTML: `
            const displayCookieBanner = () => {
              document.querySelector('#CybotCookiebotDialog')?.setAttribute('style', 'opacity:1;');
              window.removeEventListener('mousemove', displayCookieBanner);
              window.removeEventListener('touchmove', displayCookieBanner);
              window.removeEventListener('keydown', displayCookieBanner);
            };
            window.addEventListener('CookiebotOnDialogInit', () => {
              if (Cookiebot.getDomainUrlParam('CookiebotScan') === 'true') {
                Cookiebot.setOutOfRegion();
              }
            });
            window.addEventListener('CookiebotOnDialogDisplay', () => {
              window.addEventListener(
                'CookiebotOnDecline',
                () => {
                  window.location.reload();
                },
                {
                  passive: false,
                  once: true,
                }
              );
              document
                .querySelector('#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection')
                .addEventListener(
                  'click',
                  () => {
                    window.addEventListener('CookiebotOnAccept', () => {
                      window.location.reload();
                    },
                    {
                      passive: false,
                      once: true,
                    });
                  },
                  {
                    passive: false,
                    once: true,
                  }
                );

              if (Cookiebot.consentID !== '0') {
                displayCookieBanner();
              } else {
                window.addEventListener('mousemove', displayCookieBanner, {
                  once: true,
                });
                window.addEventListener('touchmove', displayCookieBanner, {
                  once: true,
                });
                window.addEventListener('keydown', displayCookieBanner, {
                  once: true,
                });
              }
            });
            `,
        },
        ...schemaObjects.value,
        ...headPageScripts,
      ]
    : []
);

const preloadSources = computed(() => {
  if (
    ![
      'HeroCarousel',
      'MultipurposeImageBanner',
      'HeroBanner',
      'IOrderMenu',
      'GalleryBase',
      'PubHeroSplitBanner',
    ].includes(topMainComponentData?.componentName)
  )
    return null;

  // PubHeroSplitBanner main image can be empty, preload must be ignored
  if (
    topMainComponentData.componentName === 'PubHeroSplitBanner' &&
    !topMainComponentData.fields?.pubMainImage?.value?.src
  )
    return null;

  const data = (() => {
    if (topMainComponentData.componentName === 'PubHeroSplitBanner')
      return {
        componentName: 'PubHeroSplitBanner',
        fields: {
          imageDesktop: topMainComponentData.fields.pubMainImage,
        },
      };
    if (topMainComponentData.componentName === 'HeroCarousel')
      return topMainComponentData?.placeholders['hero-carousel-items']?.find((s) => s.fields);
    if (topMainComponentData.componentName === 'IOrderMenu')
      return { componentName: 'StickyBanner', fields: topMainComponentData.fields };
    if (topMainComponentData.componentName === 'GalleryBase')
      return {
        componentName: topMainComponentData.componentName,
        fields: {
          imageDesktop: {
            value: { src: topMainComponentData.fields.slides?.value?.[0]?.src },
          },
        },
      };
    if (topMainComponentData.componentName === 'MultipurposeImageBanner')
      return {
        ...topMainComponentData,
        componentName: `${topMainComponentData.componentName}--${topMainComponentData.params.backgroundType}`,
        skipLargestBreakpoint: !topMainComponentData.params?.fullWidth,
      };
    return topMainComponentData;
  })();

  const { getSources } = useSources({
    componentName: data?.componentName,
    desktopUrl: data.fields?.imageDesktop?.value?.src ?? data.fields?.heroDesktop?.value?.src,
    tabletUrl: data.fields?.imageTablet?.value?.src ?? data.fields?.heroTablet?.value?.src,
    mobileUrl: data.fields?.imageMobile?.value?.src ?? data.fields?.heroMobile?.value?.src,
    skipLargestBreakpoint: data?.skipLargestBreakpoint ?? false,
  });

  return getSources.value;
});

const preloadImages = computed(() => {
  const medias = [
    '(max-width: 430px)',
    '(min-width: 431px) and (max-width: 767px)',
    '(min-width: 768px) and (max-width: 1023px)',
    '(min-width: 1024px) and (max-width: 1539px)',
    '(min-width: 1540px)',
  ];

  return (
    preloadSources?.value?.map((s, i) => {
      return s
        ? {
            rel: 'preload',
            href: s,
            fetchpriority: 'high',
            as: 'image',
            media: medias[i],
          }
        : {};
    }) ?? []
  );
});

useHead({
  title: props.route?.fields?.metaTitle?.value ?? '',
  meta: [
    {
      name: 'description',
      content: props.route?.fields?.metaDescription?.value ?? '',
    },
    {
      name: 'keywords',
      content: props.route?.fields?.metaKeywords?.value ?? '',
    },
    {
      name: 'robots',
      content: metaRobots,
    },
    {
      property: 'og:title',
      content: (props.route?.fields?.ogTitle?.value || props.route?.fields?.metaTitle?.value) ?? '',
    },
    {
      property: 'og:description',
      content:
        (props.route?.fields?.ogDescription?.value ||
          props.route?.fields?.metaDescription?.value) ??
        '',
    },
    {
      property: 'og:image',
      content: props.route?.fields?.ogImage?.value?.src ?? '',
    },
    {
      property: 'google-site-verification',
      content: sitecoreContext.googleSiteVerification ?? '',
    },
  ],
  bodyAttrs: {
    class: [
      props.route?.fields?.alternativeBackground?.value ? 'alternative-background' : null,
    ].join(' '),
  },
  link: [
    {
      rel: 'shortcut icon',
      type: 'image/x-icon',
      href: favicon || '/dist/gk-jss-app/favicon.ico',
    },
    {
      rel: 'apple-touch-icon',
      href: favicon || '/dist/gk-jss-app/favicon.ico',
    },
    {
      rel: 'canonical',
      href: canonicalUrl ?? fullUrl.value,
    },
    ...preloadImages.value,
    ...gtmLink.value,
  ],
  script: [...headScripts.value, ...bodyPageScripts],
  noscript: [...headPageNoScripts, ...bodyPageNoScripts],
});

onMounted(() => {
  enableA11yFocus();
});

onBeforeUnmount(() => {
  disableA11yFocus();
});
</script>

<script>
export default {
  name: 'MainLayout',
};
</script>
