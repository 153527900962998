import { useQuery } from '@tanstack/vue-query';
import axios from 'axios';

const fetchEvents = (venueId) =>
  axios({ url: `/api/events/getsportfeed/${venueId}`, timeout: 10000 });

const useSportEventsQuery = (venueId) => {
  return useQuery(['sportEvents'], () => fetchEvents(venueId), {
    retry: 3,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    staleTime: 1000 * 60 * 60 * 24,
  });
};

export default useSportEventsQuery;
