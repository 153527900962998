import { onMounted, ref } from 'vue';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { pxToRem } from './scalingUtils';

const useMedia = (mediaQuery) => {
  const matchMedia = !isServer() ? window.matchMedia(pxToRem(mediaQuery)) : { matches: false };
  const isMedia = ref(matchMedia.matches);

  const checkMediaMatches = () => {
    isMedia.value = matchMedia.matches;
  };

  if (!isServer()) matchMedia.onchange = checkMediaMatches;

  onMounted(() => {
    checkMediaMatches();
  });

  return { isMedia };
};

export default useMedia;
