<template>
  <div class="mobile-menu-main">
    <nav class="mobile-menu-main-navigation">
      <transition
        name="mobile-menu-main-list-transition"
        mode="out-in"
        @enter="focusOnFirstListElement"
      >
        <ul v-if="isMainListActive" ref="mainList" class="mobile-menu-main-list">
          <li
            v-for="(item, itemIdx) in navItems"
            :key="itemIdx"
            class="mobile-menu-main-list__item"
          >
            <component
              v-bind="getLinkBindings(item?.fields?.link)"
              :is="getLinkTag(item?.fields?.link?.value?.linktype)"
              v-if="!item.fields.subItems?.length"
              class="no-underline"
              :class="listItemClasses"
              :data-test-id="`mobile-nav-1lvl-${itemIdx}`"
              @click="handleNavigationClick(item?.fields?.title?.value)"
            >
              {{ item.fields.title.value }}
            </component>
            <span v-else class="mobile-menu-main-list-btn-wrapper">
              <button
                :class="listItemClasses"
                :aria-label="`Show ${item.fields.title.value.toLowerCase()} submenu`"
                :aria-expanded="isSubItemMenuExpanded"
                aria-controls="mobile-menu-main-sublist"
                :data-test-id="`mobile-nav-1lvl-${itemIdx}`"
                @click="openLink($event, item)"
              >
                <span>{{ item.fields.title.value }}</span>
                <i class="icon icon-Forward" aria-hidden="true"></i>
              </button>
            </span>
          </li>
        </ul>
        <ul
          v-else-if="!isSubItem2MenuActive"
          id="mobile-menu-main-sublist"
          ref="subList"
          class="mobile-menu-main-list mobile-menu-main-sublist"
        >
          <li class="mobile-menu-main-list__item">
            <span class="mobile-menu-main-list-btn-wrapper mobile-menu-main-list-btn-wrapper--back">
              <button
                :class="listItemClasses"
                data-test-id="go-back-to-the-main-menu-btn"
                @click="goBack"
              >
                <i class="icon icon-Back" aria-hidden="true"></i>
                <span>{{ !threeLvl ? activeItem.title : t('navigation-back') }}</span>
              </button>
            </span>
          </li>
          <li
            v-for="(item, itemIdx) in activeItem.subItems"
            :key="itemIdx"
            class="mobile-menu-main-list__item"
          >
            <component
              :is="getLinkTag(item?.fields?.link?.value?.linktype)"
              v-if="!item.fields?.subItems?.length"
              v-bind="getLinkBindings(item?.fields?.link)"
              :data-test-id="`mobile-nav-2lvl-${itemIdx}`"
              class="no-underline"
              @click="
                handleNavigationClick(item?.fields?.title?.value, item?.fields?.link?.value?.href)
              "
            >
              {{ item.fields.title.value }}
            </component>
            <span v-else class="mobile-menu-main-list-btn-wrapper">
              <button
                :class="listItemClasses"
                :aria-label="`Show ${item.fields.title.value.toLowerCase()} submenu`"
                :aria-expanded="isSubItem2MenuExpanded"
                aria-controls="mobile-menu-main-sublist"
                :data-test-id="`mobile-nav-1lvl-${itemIdx}`"
                @click="openLink($event, item, true)"
              >
                <span>{{ item.fields.title.value }}</span>
                <i class="icon icon-Forward" aria-hidden="true"></i>
              </button>
            </span>
          </li>
        </ul>
        <ul
          v-else
          id="mobile-menu-main-sublist-2"
          ref="subList2"
          class="mobile-menu-main-list mobile-menu-main-sublist-2"
        >
          <li class="mobile-menu-main-list__item">
            <span class="mobile-menu-main-list-btn-wrapper mobile-menu-main-list-btn-wrapper--back">
              <button
                :class="listItemClasses"
                data-test-id="go-back-to-the-2nd-lvl-menu-btn"
                @click="openLink($event, activeItemParent)"
              >
                <i class="icon icon-Back" aria-hidden="true"></i>
                <span>{{ t('navigation-back') }}</span>
              </button>
            </span>
          </li>
          <li
            v-for="(item, itemIdx) in activeItem.subItems"
            :key="itemIdx"
            class="mobile-menu-main-list__item"
          >
            <component
              :is="getLinkTag(item?.fields?.link?.value?.linktype)"
              v-bind="getLinkBindings(item?.fields?.link)"
              :data-test-id="`mobile-nav-2lvl-${itemIdx}`"
              class="no-underline"
              @click="
                handleNavigationClick(item?.fields?.title?.value, item?.fields?.link?.value?.href)
              "
            >
              {{ item.fields?.title?.value ?? item.fields?.link?.value?.text }}
            </component>
          </li>
        </ul>
      </transition>
    </nav>
    <div class="mobile-menu-main-btn-wrapper">
      <base-button
        v-if="fields?.ctaHeaderLink?.value?.href"
        class="mobile-menu-main-btn"
        :variant="brandName !== 'PubPartners' ? 'secondary' : 'primary'"
        type="link"
        :link="fields?.ctaHeaderLink?.value"
        data-test-id="header-cta-mobile-button"
        :label="t('header-cta-text-short')"
        @click="handleBookingClick(t('header-cta-text-short'))"
      />
      <base-button
        v-for="option in bookingOptions"
        v-else
        :key="option.key"
        class="mobile-menu-main-btn"
        variant="secondary"
        type="link"
        :link="option.data"
        :label="bookingOptions.length > 1 ? t(option.labelKey) : t('book-cta-text-long')"
        :data-test-id="`${option.key}-mobile-button`"
        @click="
          handleBookingClick(
            bookingOptions.length > 1 ? t(option.labelKey) : t('book-cta-text-long')
          )
        "
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { getLinkTag, getLinkBindings } from '../../../../utils/linkTransformer';
import BaseButton from '../../../2_elements/BaseButton/BaseButton.vue';

const { t } = useI18n();

const jssStore = inject('jssStore');
const threeLvl = inject('threeLvl');
const brandName = jssStore?.sitecoreContext()?.brandName;

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  bookingOptions: {
    type: Array,
    default: () => [],
  },
  handleBookingClick: {
    type: Function,
  },
  handleNavigationClick: {
    type: Function,
  },
});

const activeItem = ref({});
const activeItemParent = ref({});
const isSubItemMenuExpanded = ref(false);
const isSubItem2MenuExpanded = ref(false);
const mainList = ref(null);
const subList = ref(null);
const subList2 = ref(null);

const isMainListActive = computed(
  () => !activeItem.value?.areSubItemsActive && !activeItem.value?.areSubItems2Active
);
const isSubItem2MenuActive = computed(() => activeItem.value?.areSubItems2Active);
const listItemClasses = computed(() => ({
  'font-s': ['HungryHorse', 'ChefBrewer'].includes(brandName),
}));

const createNavItem = (item) => {
  if (!item.fields?.subItems?.length) {
    return item;
  }

  const exploreItem = item.fields.link?.value.href
    ? {
        ...item,
        fields: {
          ...item.fields,
          title: {
            value: `${t('navigation-explore')} ${
              item.fields.title?.value || item.fields.link?.value.text
            }`,
          },
          subItems: [],
        },
      }
    : null;

  const subItems = item.fields.subItems?.map(createNavItem);

  return {
    ...item,
    fields: {
      ...item.fields,
      subItems: [exploreItem, ...subItems].filter(Boolean),
    },
  };
};

const threeLvlNavItems = computed(() => props.fields?.navItems.map(createNavItem));
const navItems = computed(() => (!threeLvl ? props.fields?.navItems : threeLvlNavItems.value));

const openLink = (event, item, thirdLvl = false) => {
  isSubItemMenuExpanded.value = !thirdLvl;
  isSubItem2MenuExpanded.value = thirdLvl;
  const itemTitle = item.fields.title.value;
  const itemSubItems = item.fields.subItems;

  setTimeout(() => {
    if (itemSubItems?.length > 0) {
      event.preventDefault();
      activeItem.value = {
        title: itemTitle,
        subItems: itemSubItems,
        areSubItemsActive: !thirdLvl,
        areSubItems2Active: thirdLvl,
      };
      if (!thirdLvl) {
        activeItemParent.value = item;
      }
    }
  }, 100);
};

const goBack = () => {
  isSubItemMenuExpanded.value = false;
  activeItem.value.areSubItemsActive = false;
};

const focusOnFirstListElement = () => {
  mainList.value?.querySelector('button')?.focus();
  subList.value?.querySelector('button')?.focus();
  subList2.value?.querySelector('button')?.focus();
};

defineExpose({ mainList, subList, subList2 });
</script>

<style lang="scss">
@import './scss/MobileMenuMain.scss';
</style>
