<template>
  <section
    v-if="fields?.cookieBotId?.value && !isServer()"
    class="cookie-declaration content-block rich-text"
  >
    <div class="container">
      <div class="row">
        <div ref="cookieContentRef" class="col-lg-8 offset-lg-2">
          <component
            :is="'script'"
            id="CookieDeclaration"
            :src="`https://consent.cookiebot.com/${fields?.cookieBotId?.value}/cd.js`"
            async
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { isServer } from '@sitecore-jss/sitecore-jss';
import { ref, onMounted } from 'vue';
import useRichTextTableTabindex from '../../utils/useRichTextTableTabindex';
import { useMutationObserver } from '@vueuse/core';

defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const cookieContentRef = ref(null);
const { calculateTablesTabindex } = useRichTextTableTabindex(cookieContentRef);

const mutationObserverCallback = () => {
  (async () => {
    await calculateTablesTabindex();
  })();
};

onMounted(() => {
  if (cookieContentRef.value) {
    useMutationObserver(cookieContentRef.value, mutationObserverCallback, {
      childList: true,
      subtree: true,
    });
  }
});
</script>

<style lang="scss">
@import '../RichText/scss/RichText.scss';
</style>
