<!-- Component to use in Experience Editor for displaying messages about missing datasources and missing placeholder's items -->
<template>
  <div class="error-component show-on-load" v-text="errorText"></div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  rendering: {
    type: Object,
    default: () => ({}),
  },
});

const MISSING_CHILD = 'Item is missing child item.';
const MISSING_DATASOURCE = 'Item is missing data source.';

const errorText = computed(() =>
  [
    props.rendering?.validatedComponentName + ':',
    props.rendering?.missingDatasource && MISSING_DATASOURCE,
    props.rendering?.missingChildren && MISSING_CHILD,
  ]
    .filter(Boolean)
    .join(' ')
);
</script>
