import { useElementSize, useMutationObserver, useDebounceFn } from '@vueuse/core';
import { ref, watch, onMounted } from 'vue';

const useNavScroll = (activeScrollItemIndex, navRef, navItemRefs, variant) => {
  const scrollLeftVisible = ref(false);
  const scrollRightVisible = ref(false);

  const SCROLLBY_OFFSET_PX = 100;

  const { width } = useElementSize(navRef);

  onMounted(() => {
    useMutationObserver(
      document.head,
      () => {
        setNavScrollPosition();
      },
      {
        childList: true,
        subtree: true,
      }
    );
  });

  const onScrollIconClick = (sign) => {
    if (navRef.value) {
      navRef.value.scrollBy({ left: sign * SCROLLBY_OFFSET_PX, behavior: 'smooth' });
    }
    checkScrollVisibility();
  };

  const onScroll = () => {
    checkScrollVisibility();
  };

  const checkScrollVisibility = () => {
    const SCROLL_TOLERANCE_PX = 9;

    if (navRef.value) {
      const scrollVisible = navRef.value.clientWidth < navRef.value.scrollWidth;

      const scrollOnLeft = navRef.value.scrollLeft === 0;
      const scrollOnRight =
        Math.abs(navRef.value.scrollWidth - navRef.value.clientWidth - navRef.value.scrollLeft) <=
        SCROLL_TOLERANCE_PX;

      scrollLeftVisible.value = scrollVisible && !scrollOnLeft;
      scrollRightVisible.value = scrollVisible && !scrollOnRight;
    }
  };

  const setNavScrollPosition = useDebounceFn(() => {
    if (!navRef.value || !navItemRefs.value || activeScrollItemIndex.value == null) return;

    // This condition is required because router-link is not resolved as HTML element by ref
    const elRef =
      variant === 'links'
        ? navItemRefs.value[activeScrollItemIndex.value]?.$el
        : navItemRefs.value[activeScrollItemIndex.value];

    if (!elRef) return;

    const navPaddingLeft = parseFloat(
      window.getComputedStyle(navRef.value)?.getPropertyValue('padding-left')
    );

    const elMarginLeft = parseFloat(
      window.getComputedStyle(elRef)?.getPropertyValue('margin-left')
    );

    navRef.value.scrollLeft =
      elRef.offsetLeft - elMarginLeft - navRef.value.offsetLeft - navPaddingLeft;
  }, 1);

  watch([activeScrollItemIndex, width], async () => {
    if (!width.value) {
      return;
    }

    setNavScrollPosition();
    checkScrollVisibility();
  });

  return {
    onScroll,
    onScrollIconClick,
    scrollLeftVisible,
    scrollRightVisible,
  };
};

export default useNavScroll;
