<template>
  <section ref="richTextRef" class="rich-text content-block">
    <div class="container">
      <div class="row rich-text__row">
        <div class="col-lg-8 offset-lg-2">
          <sc-rich-text :field="fields?.richText"></sc-rich-text>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { RichText as ScRichText } from '@sitecore-jss/sitecore-jss-vue';
import { onMounted, ref } from 'vue';
import useRichTextTableTabindex from '../../utils/useRichTextTableTabindex';

defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const richTextRef = ref(null);

const { calculateTablesTabindex } = useRichTextTableTabindex(richTextRef);

onMounted(() => {
  calculateTablesTabindex();
});
</script>

<style lang="scss">
@import './scss/RichText.scss';
</style>
