<template>
  <div class="hero-carousel-item" :data-test-id="`slide-${$attrs.index}`">
    <div
      class="hero-carousel-item__img-wrapper"
      :class="{
        'hero-carousel-item__img-wrapper--with-transparent-layer':
          props?.fields?.showTransparentLayer?.value,
      }"
    >
      <base-image
        :mobile-url="props.fields?.imageMobile?.value?.src"
        :mobile-alt="props.fields?.imageMobile?.value?.alt"
        :tablet-url="props.fields?.imageTablet?.value?.src"
        :tablet-alt="props.fields?.imageTablet?.value?.alt"
        :desktop-url="props.fields?.imageDesktop?.value?.src"
        :desktop-alt="props.fields?.imageDesktop?.value?.alt"
        :mobile-dimensions="[
          props.fields?.imageMobile?.value?.width,
          props.fields?.imageMobile?.value?.height,
        ]"
        :tablet-dimensions="[
          props.fields?.imageTablet?.value?.width,
          props.fields?.imageTablet?.value?.height,
        ]"
        :desktop-dimensions="[
          props.fields?.imageDesktop?.value?.width,
          props.fields?.imageDesktop?.value?.height,
        ]"
        :full-width="true"
        :fetchpriority="$attrs.index && !isContentCard ? 'low' : 'high'"
        :lazy-loading="$attrs.index && !isContentCard ? 'lazy' : null"
        preload-image-name="HeroCarouselItem"
      />
    </div>
    <div
      class="hero-carousel-item__content"
      :class="{
        ...detectComponentVariant(variantedComponents.HERO_CAROUSEL),
      }"
    >
      <div class="container">
        <sc-text
          tag="span"
          class="hero-carousel-item__subtitle font-s"
          :field="props.fields?.subtitle"
        />
        <sc-text
          :tag="headingTag"
          class="hero-carousel-item__title"
          :class="headerTitleClass"
          :field="props.fields?.title"
        />
        <sc-text
          tag="p"
          class="hero-carousel-item__description"
          :field="props.fields?.description"
        />
        <div v-if="showCta || showSecondaryCta" class="hero-carousel-item__cta-wrapper">
          <base-button
            v-if="showCta"
            variant="primary"
            type="link"
            class="hero-carousel-item__cta hero-carousel-item__cta--primary"
            :link="props.fields?.link?.value"
            :tabindex="swiperSlide?.isVisible || !props.isCarousel ? null : -1"
            @click="handleClickCTA(props.fields?.link?.value?.text)"
          />
          <base-button
            v-if="showSecondaryCta"
            variant="secondary"
            type="link"
            class="hero-carousel-item__cta hero-carousel-item__cta--secondary"
            :link="props.fields?.secondaryLink?.value"
            :tabindex="swiperSlide?.isVisible || !props.isCarousel ? null : -1"
            @click="handleClickCTA(props.fields?.secondaryLink?.value?.text)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, useAttrs, onMounted } from 'vue';
import { Text as ScText } from '@sitecore-jss/sitecore-jss-vue';
import BaseButton from '../../2_elements/BaseButton/BaseButton.vue';
import BaseImage from '../../2_elements/BaseImage/BaseImage.vue';
import { useSwiperSlide } from 'swiper/vue';
import { useRegisterDataLayerAction } from '../../../data-layer/helpers/registerDataLayer';
import { useDetectVariantByBrand, variantedComponents } from '../../../utils/componentVariants';
import { useContentCardsStore } from '../../../stores/contentCardsStore';

const { logContentCardClick, logContentCardDisplay } = useContentCardsStore();
const detectComponentVariant = useDetectVariantByBrand();

const jssStore = inject('jssStore');
const brandName = jssStore?.sitecoreContext()?.brandName;

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  isCarousel: {
    type: Boolean,
    default: false,
  },
  headingType: {
    type: String,
    default: 'h1',
  },
  isContentCard: {
    type: Boolean,
    default: false,
  },
});

const attrs = useAttrs();

const headingTag = computed(() => {
  if (props.headingType !== 'h1') return props.headingType;
  return !attrs.index ? 'h1' : 'h2';
});

const headerTitleClass = computed(() => [
  ['GreeneKingInns', 'Belhaven', 'Metro'].includes(brandName) ? 'font-xxl' : 'font-xl',
]);

const swiperSlide = props.isCarousel ? useSwiperSlide() : null;

const showCta = computed(
  () => !!props.fields?.link?.value?.href && !!props.fields?.link?.value?.text
);
const showSecondaryCta = computed(
  () => !!props.fields?.secondaryLink?.value?.href && !!props.fields?.secondaryLink?.value?.text
);

const registerDataLayerAction = useRegisterDataLayerAction();

const handleClickCTA = (action) => {
  registerDataLayerAction({
    event_name: 'click_button',
    link_text: action,
    intent: action,
    component: 'hero_carousel_item',
  });
  if (props.isContentCard) logContentCardClick(props.fields.contentCardId);
};

onMounted(() => {
  if (props.isContentCard) logContentCardDisplay(props.fields.contentCardId);
});
</script>

<script>
export default {
  name: 'DisablingAttributeInheritance',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<style lang="scss">
@import './scss/HeroCarouselItem.scss';
</style>
