<template><template v-if="`${false}`"></template></template>
<script setup>
/**
  VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
  If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
  For XM (CMS-only) apps, this should be removed.

  VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
 */
import { inject } from 'vue';
import { useHead } from '@unhead/vue';

const jssStore = inject('jssStore');
const visitorIdentificationTimestamp = jssStore.sitecoreContext().visitorIdentificationTimestamp;

visitorIdentificationTimestamp &&
  useHead({
    meta: [
      {
        name: 'VIcurrentDateTime',
        content: visitorIdentificationTimestamp,
      },
    ],
    script: {
      src: `/layouts/system/VisitorIdentification.js`,
      async: true,
    },
  });
</script>
