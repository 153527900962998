<template>
  <section class="sticky-banner">
    <div class="sticky-banner__hero">
      <base-image
        class="sticky-banner__image"
        :mobile-url="fields?.heroMobile?.value?.src"
        :tablet-url="fields?.heroTablet?.value?.src"
        :desktop-url="fields?.heroDesktop?.value?.src"
        :desktop-alt="fields?.heroDesktop?.value?.alt"
        :tablet-alt="fields?.heroTablet?.value?.alt"
        :mobile-alt="fields?.heroMobile?.value?.alt"
        :full-width="true"
      />
      <div class="container sticky-banner__content">
        <sc-text
          tag="h1"
          class="sticky-banner__title"
          :class="titleClasses"
          :field="fields?.title"
        />
        <div class="sticky-banner__placeholder"></div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, inject } from 'vue';
import { Text as ScText } from '@sitecore-jss/sitecore-jss-vue';
import BaseImage from '../../2_elements/BaseImage/BaseImage.vue';

const jssStore = inject('jssStore');
const brandName = jssStore?.sitecoreContext()?.brandName;

defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const titleClasses = computed(() => [brandName === 'Belhaven' ? 'font-xxl' : 'font-xl']);
</script>
<style lang="scss">
@import './scss/StickyBanner.scss';
</style>
