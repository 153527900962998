<template>
  <section class="sport-hero-banner-item">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 offset-xl-1">
          <div class="sport-hero-banner-item__image">
            <base-image
              class="sport-hero-banner-item__pic"
              :mobile-url="fields.imageMobile?.value?.src"
              :mobile-alt="fields.imageMobile?.value?.alt"
              :tablet-url="fields.imageTablet?.value?.src"
              :tablet-alt="fields.imageTablet?.value?.alt"
              :desktop-url="fields.imageDesktop?.value?.src"
              :desktop-alt="fields.imageDesktop?.value?.alt"
              :mobile-dimensions="[
                fields.imageMobile?.value?.width,
                fields.imageMobile?.value?.height,
              ]"
              :tablet-dimensions="[
                fields.imageTablet?.value?.width,
                fields.imageTablet?.value?.height,
              ]"
              :desktop-dimensions="[
                fields.imageDesktop?.value?.width,
                fields.imageDesktop?.value?.height,
              ]"
              :full-width="true"
              fetchpriority="high"
              preload-image-name="SportHeroBannerItem"
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-5 sport-hero-banner-item__content">
          <sc-text
            tag="h1"
            class="sport-hero-banner-item__title"
            :class="titleClasses"
            :field="fields.title"
          />
          <sc-text
            tag="span"
            class="sport-hero-banner-item__subtitle font-s"
            :field="fields.subtitle"
          />
          <sc-text
            tag="p"
            class="sport-hero-banner-item__description"
            :field="fields.description"
          />
          <div class="sport-hero-banner-item__logos-wrapper">
            <base-image
              v-if="fields.logo1?.value?.src"
              :desktop-url="fields.logo1.value.src"
              :desktop-alt="fields.logo1.value.alt"
              :desktop-dimensions="[32, 32]"
              :custom-params="{ desktop: 32, tablet: 32, mobile: 32, mobileS: 32 }"
              fallback-param="h"
              fetchpriority="high"
            />
            <base-image
              v-if="fields.logo2?.value?.src"
              :desktop-url="fields.logo2.value.src"
              :desktop-alt="fields.logo2.value.alt"
              :desktop-dimensions="[32, 32]"
              :custom-params="{ desktop: 32, tablet: 32, mobile: 32, mobileS: 32 }"
              fallback-param="h"
              fetchpriority="high"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, inject } from 'vue';
import { Text as ScText } from '@sitecore-jss/sitecore-jss-vue';
import BaseImage from '../../2_elements/BaseImage/BaseImage.vue';

defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const jssStore = inject('jssStore');
const brandName = jssStore?.sitecoreContext()?.brandName;

const titleClasses = computed(() => [brandName === 'Belhaven' ? 'font-xxl' : 'font-xl']);
</script>

<style lang="scss">
@import './scss/SportHeroBannerItem.scss';
</style>
