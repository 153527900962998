import { onBeforeUnmount, onMounted } from 'vue';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-vue';
import { useThrottleFn } from '@vueuse/core';

const useRichTextTableTabindex = (wrapperRef) => {
  const calculateTablesTabindex = useThrottleFn(() => {
    if (!wrapperRef.value) {
      return;
    }

    Array.from(wrapperRef.value.querySelectorAll('table')).forEach((table) => {
      const isTableOverflowing =
        table.scrollWidth > table.clientWidth || table.scrollHeight > table.clientHeight;
      const isTableFocusable = table.getAttribute('tabindex') !== null;

      if (isTableOverflowing && !isTableFocusable) {
        table.setAttribute('tabindex', '0');
      } else if (!isTableOverflowing && isTableFocusable) {
        table.removeAttribute('tabindex');
      }
    });
  }, 100);

  const onWindowResize = () => {
    (async () => {
      await calculateTablesTabindex();
    })();
  };

  onMounted(() => {
    if (!isEditorActive()) {
      window.addEventListener('resize', onWindowResize);
    }
  });

  onBeforeUnmount(() => {
    if (!isEditorActive()) {
      window.removeEventListener('resize', onWindowResize);
    }
  });

  return { calculateTablesTabindex };
};

export default useRichTextTableTabindex;
