<template>
  <component
    :is="getLinkTag(props?.link?.value?.linktype)"
    class="mega-nav-item no-underline"
    v-bind="getLinkBindings(link)"
  >
    <div class="mega-nav-item__image-wrapper">
      <base-image
        :desktop-url="image?.value?.src"
        :desktop-alt="image?.value?.alt"
        :custom-params="{ tablet: imageParam, desktop: imageParam }"
        loading="lazy"
        fallback-param="nav"
        fetchpriority="low"
      />
    </div>
    <sc-text class="mega-nav-item__title" tabindex="-1" :field="title" />
    <sc-text v-if="description?.value" class="mega-nav-item__description" :field="description" />
  </component>
</template>

<script setup>
import { Text as ScText } from '@sitecore-jss/sitecore-jss-vue';
import { getLinkBindings, getLinkTag } from '../../../../utils/linkTransformer';
import BaseImage from '../../../2_elements/BaseImage/BaseImage.vue';

const props = defineProps({
  image: {
    type: Object,
  },
  imageParam: {
    type: String,
  },
  title: {
    type: Object,
  },
  description: {
    type: Object,
  },
  link: {
    type: Object,
  },
});
</script>

<style lang="scss">
@import './scss/MegaNavItem.scss';
</style>
