<template>
  <router-view />
</template>

<script setup>
import { onMounted } from 'vue';
import { useContentCardsStore } from './stores/contentCardsStore';

const { subscribeContentCardFeed } = useContentCardsStore();

onMounted(() => {
  subscribeContentCardFeed();
});
</script>
