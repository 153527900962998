<template>
  <button
    :data-src="componentBindings.href"
    :class="componentBindings.classes"
    v-bind="btnBindings"
    @click="openIframeBar"
  >
    {{ text || field.value?.text }}
  </button>
  <template v-if="iframeUrl">
    <Teleport to="body">
      <IframeBar
        :src="iframeUrl"
        :is-open="isOpen"
        :header="t('book-room-cta-long')"
        @on-close="closeIframeBar"
      />
    </Teleport>
  </template>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import IframeBar from './IframeBar/IframeBar.vue';

const { t } = useI18n();

const props = defineProps({
  field: {
    type: Object,
    default: () => ({}),
  },
  href: {
    type: String,
    default: () => null,
  },
  text: {
    type: String,
    default: () => null,
  },
  class: {
    type: String,
    default: () => null,
  },
  dataTestId: {
    type: String,
    default: () => null,
  },
  tabindex: {
    type: Number,
    default: () => null,
  },
  baseButton: {
    type: Boolean,
    default: () => false,
  },
});

const componentBindings = computed(() => {
  return {
    rel: props.field?.value?.target == '_blank' ? 'noopener noreferrer' : null,
    alt: props.field?.value?.alt || null,
    href: props.href || props.field?.value?.href,
    classes: props.class || props.field?.value?.class,
  };
});

const btnBindings = computed(() =>
  props.baseButton
    ? {
        ariaLabel: t('book-room-cta-long'),
        'data-cta-text-short': t('book-cta-text-short'),
        'data-cta-text-long': t('book-room-cta-long'),
        tabindex: props.tabindex,
      }
    : null
);

const isOpen = ref(false);
const iframeUrl = ref('');

const openIframeBar = () => {
  if (!iframeUrl.value) iframeUrl.value = componentBindings.value.href;
  setTimeout(() => (isOpen.value = true), 0);
};

const closeIframeBar = () => {
  isOpen.value = false;
};
</script>
