<template>
  <section class="sport-hero-banner show-on-load">
    <div v-if="$jss.sitecoreContext().pageEditing">
      <sport-hero-banner-item :fields="fields" />
      <sc-placeholder :name="PLACEHOLDER" :rendering="sportHeroBannerRendering" />
    </div>
    <div v-else class="sport-hero-banner__container">
      <template v-for="(item, index) in items" :key="index">
        <Transition name="sport-hero-banner">
          <sport-hero-banner-item v-if="index === currentItemIndex" :fields="item" />
        </Transition>
      </template>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue';
import { Placeholder as ScPlaceholder } from '@sitecore-jss/sitecore-jss-vue';
import SportHeroBannerItem from '../SportHeroBannerItem/SportHeroBannerItem.vue';

const PLACEHOLDER = 'sport-hero-banner-items';

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  sportHeroBannerRendering: {
    type: Object,
    default: () => ({}),
  },
  params: {
    type: Object,
  },
  activeSlide: {
    type: String,
    default: '',
  },
});

const items = computed(() => {
  const f = props.fields;
  const defaultItem = {
    description: f.description,
    imageDesktop: f.imageDesktop,
    imageMobile: f.imageMobile,
    imageTablet: f.imageTablet,
    logo1: f.logo1,
    logo2: f.logo2,
    subtitle: f.subtitle,
    title: f.title,
  };
  const placeholderItems =
    props.sportHeroBannerRendering.placeholders?.[PLACEHOLDER]?.filter((item) => item.fields).map(
      (item) => item.fields
    ) ?? [];
  return [defaultItem, ...placeholderItems];
});

const currentItemIndex = computed(() => {
  const item = items.value.findIndex(
    (item) => item.sportOrTournamentReference?.value === props.activeSlide
  );

  return item > 0 ? item : 0;
});
</script>

<style lang="scss">
@import './scss/SportHeroBanner.scss';
</style>
