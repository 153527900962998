<template>
  <div class="loading-spinner">
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M56 32C56 45.2548 45.2548 56 32 56C18.7452 56 8 45.2548 8 32C8 18.7452 18.7452 8 32 8C45.2548 8 56 18.7452 56 32ZM12.8 32C12.8 42.6039 21.3961 51.2 32 51.2C42.6039 51.2 51.2 42.6039 51.2 32C51.2 21.3961 42.6039 12.8 32 12.8C21.3961 12.8 12.8 21.3961 12.8 32Z"
        fill="#DFDFDF"
      />
      <path
        d="M53.6 32C54.9255 32 56.0124 33.0772 55.8801 34.396C55.5393 37.7924 54.4768 41.0868 52.7531 44.0544C50.6264 47.7157 47.569 50.7493 43.891 52.8472C40.2131 54.945 36.0456 56.0325 31.8115 55.9993C27.5775 55.966 23.4276 54.8132 19.783 52.6578C16.1385 50.5024 13.129 47.4212 11.0601 43.7269C8.99118 40.0326 7.93645 35.8567 8.00296 31.623C8.06947 27.3894 9.25484 23.2486 11.4388 19.6211C13.2089 16.681 15.5839 14.1629 18.396 12.228C19.488 11.4767 20.9556 11.9102 21.5941 13.0718V13.0718C22.2327 14.2333 21.7976 15.681 20.7248 16.4594C18.646 17.9676 16.8841 19.8827 15.551 22.0969C13.8039 24.9989 12.8556 28.3115 12.8024 31.6984C12.7492 35.0854 13.5929 38.4261 15.2481 41.3815C16.9032 44.337 19.3108 46.8019 22.2264 48.5262C25.142 50.2506 28.462 51.1728 31.8492 51.1994C35.2365 51.226 38.5704 50.356 41.5128 48.6777C44.4552 46.9994 46.9011 44.5726 48.6025 41.6435C49.9006 39.4086 50.73 36.9421 51.0502 34.3938C51.2155 33.0787 52.2745 32 53.6 32V32Z"
        fill="black"
      />
    </svg>
  </div>
</template>

<style lang="scss">
@import './scss/LoadingSpinner.scss';
</style>
