<template>
  <section class="video-hero-banner show-on-load" :class="mainClasses">
    <div class="video-hero-banner__container">
      <div class="video-hero-banner__wrapper">
        <div
          class="video-hero-banner__media-wrapper"
          :class="{
            'video-hero-banner__media-wrapper--with-transparent-layer':
              fields.showTransparentLayer?.value,
          }"
        >
          <div
            v-if="!isServer() && fields.videoDesktop?.value?.href"
            class="video-hero-banner__video-container"
          >
            <video
              v-if="isDesktopVideo"
              ref="videoRef"
              class="video-hero-banner__video"
              :autoplay="!isVideoPaused"
              muted
              playsinline
              loop
              @play="hideCoverImg"
            >
              <source :src="fields.videoDesktop.value.href" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <video
              v-else
              ref="videoRef"
              class="video-hero-banner__video"
              :autoplay="!isVideoPaused"
              muted
              playsinline
              loop
              @play="hideCoverImg"
            >
              <source :src="fields.videoMobile.value.href" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <Transition name="video-hero-banner__animation">
            <div v-if="isCoverVisible" class="video-hero-banner__image-container">
              <base-image
                :mobile-url="fields.imageMobile?.value?.src"
                :mobile-alt="fields.imageMobile?.value?.alt"
                :tablet-url="fields.imageTablet?.value?.src"
                :tablet-alt="fields.imageTablet?.value?.alt"
                :desktop-url="fields.imageDesktop?.value?.src"
                :desktop-alt="fields.imageDesktop?.value?.alt"
                :mobile-dimensions="[
                  fields.imageMobile?.value?.width,
                  fields.imageMobile?.value?.height,
                ]"
                :tablet-dimensions="[
                  fields.imageTablet?.value?.width,
                  fields.imageTablet?.value?.height,
                ]"
                :desktop-dimensions="[
                  fields.imageDesktop?.value?.width,
                  fields.imageDesktop?.value?.height,
                ]"
                :full-width="true"
                fetchpriority="high"
                preload-image-name="VideoHeroBannerCover"
              />
            </div>
          </Transition>
        </div>
        <div class="video-hero-banner__content var-dark">
          <div class="container">
            <sc-text
              tag="span"
              class="video-hero-banner__subtitle font-s"
              :field="fields.subtitle"
            />
            <sc-text
              :tag="params.headingType ?? 'h1'"
              class="video-hero-banner__title"
              :class="params.headingType === 'h1' && h1HeaderTitleClass"
              :field="fields.title"
            />
            <sc-text tag="p" class="video-hero-banner__description" :field="fields.description" />
            <div v-if="showPrimaryCta || showSecondaryCta" class="video-hero-banner__cta-wrapper">
              <base-button
                v-if="showPrimaryCta"
                variant="primary"
                type="link"
                class="video-hero-banner__cta video-hero-banner__cta--primary"
                :link="fields.link?.value"
                @click="handleClickCTA(fields.link?.value?.text)"
              />
              <base-button
                v-if="showSecondaryCta"
                variant="secondary"
                type="link"
                class="video-hero-banner__cta video-hero-banner__cta--secondary"
                :link="fields.secondaryLink?.value"
                @click="handleClickCTA(fields.secondaryLink?.value?.text)"
              />
            </div>
          </div>
        </div>
        <div class="video-hero-banner__autoplay var-dark">
          <div class="container">
            <button
              class="video-hero-banner__autoplay-btn"
              :aria-label="isVideoPaused ? 'Play video' : 'Pause video'"
              @click="handleClickAutoplay"
            >
              <i
                :class="['icon', isVideoPaused ? 'icon-Play' : 'icon-Pause']"
                aria-hidden="true"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, inject, ref } from 'vue';
import { Text as ScText } from '@sitecore-jss/sitecore-jss-vue';
import { isServer } from '@sitecore-jss/sitecore-jss';
import BaseButton from '../2_elements/BaseButton/BaseButton.vue';
import BaseImage from '../2_elements/BaseImage/BaseImage.vue';
import { useRegisterDataLayerAction } from '../../data-layer/helpers/registerDataLayer';
import useMedia from '../../utils/useMedia';

const { isMedia: isDesktop } = useMedia('(min-width: 1024px)');

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
    default: () => ({}),
  },
});

const videoRef = ref(null);
const isVideoPaused = ref(false);

const jssStore = inject('jssStore');
const brandName = jssStore?.sitecoreContext()?.brandName;

const isCoverVisible = ref(true);

const mainClasses = computed(() => [brandName === 'Belhaven' && 'blh-full-width']);

const h1HeaderTitleClass = computed(() => [
  ['GreeneKingInns', 'Belhaven', 'Metro'].includes(brandName) ? 'font-xxl' : 'font-xl',
]);

const showPrimaryCta = computed(
  () => !!props.fields.link?.value?.href && !!props.fields.link?.value?.text
);
const showSecondaryCta = computed(
  () => !!props.fields.secondaryLink?.value?.href && !!props.fields.secondaryLink?.value?.text
);

const isDesktopVideo = computed(() =>
  isDesktop.value ? true : !props.fields.videoMobile?.value?.href
);

const registerDataLayerAction = useRegisterDataLayerAction();

const handleClickCTA = (action) => {
  registerDataLayerAction({
    event_name: 'click_button',
    link_text: action,
    intent: action,
    component: 'video_hero_banner',
  });
};

const handleClickAutoplay = () => {
  isVideoPaused.value = !isVideoPaused.value;
  isVideoPaused.value ? videoRef.value.pause() : videoRef.value.play();
};

const hideCoverImg = () => {
  isCoverVisible.value = false;
};
</script>

<style lang="scss">
@import './scss/VideoHeroBanner.scss';
</style>
