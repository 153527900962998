<template>
  <sport-hero-banner
    v-if="fields.imageDesktop?.value"
    :fields="fields"
    :params="params"
    :sport-hero-banner-rendering="rendering"
    :active-slide="activeSlide"
  />
  <div v-if="isLoading" class="sport-events__loading show-on-load">
    <span class="sport-events__content-loader-info" role="alert" aria-live="assertive">
      {{ $t('events-content-loading') }}
    </span>
    <loading-placeholder :items-number="placeholderItems" modifier-name="sport-events" />
  </div>
  <sport-event-list-main
    :fields="fields"
    :data="data?.data"
    :is-loading="isLoading"
    :is-error="isError"
    @filter-change="onChangeSlide"
  />
</template>

<script setup>
import { defineAsyncComponent, ref, computed } from 'vue';
import SportHeroBanner from './SportHeroBanner/SportHeroBanner.vue';
import LoadingPlaceholder from '../2_elements/LoadingPlaceholder/LoadingPlaceholder.vue';
import useSportEventsQuery from '../../utils/queries/useSportEventsQuery';
import useMedia from '../../utils/useMedia';

const SportEventListMain = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "SportEventListMain" */ './SportEventListMain.vue'),
});

// fix Vue extraneous non-emits event listeners warning
defineEmits(['click']);

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const { isMedia: isTablet } = useMedia('(min-width: 768px)');
const { isMedia: isDesktop } = useMedia('(min-width: 1400px)');

const { data, isLoading, isError } = useSportEventsQuery(props.fields?.venueId?.value);

const activeSlide = ref(props.fields.selectedSportOrTournamentReference?.value || null);

const placeholderItems = computed(() => {
  if (isDesktop.value) return 9;
  return isTablet.value ? 8 : 2;
});

const onChangeSlide = (value) => {
  activeSlide.value = value;
};
</script>

<style lang="scss">
@import './scss/SportEventList.scss';
</style>
