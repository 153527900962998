<template>
  <main-header v-bind="props" :three-lvl="true">
    <template #threeLvlNav="slotProps">
      <div v-if="props.fields.navItems" class="three-lvl-nav container var-dark">
        <ul v-if="slotProps.parentIndex !== null" class="three-lvl-nav__menu">
          <li
            v-for="(column, columnIndex) in selectedMenu(slotProps.parentIndex)"
            :key="columnIndex"
            class="three-lvl-nav__col"
          >
            <ul class="three-lvl-nav__col-menu">
              <li
                v-for="(navItem, navItemIndex) in column"
                :key="`${columnIndex}-${navItemIndex}`"
                class="three-lvl-nav__col-item"
              >
                <component
                  :is="getLinkTag(navItem.fields.link?.value.linktype)"
                  v-bind="getLinkBindings(navItem.fields.link)"
                  class="three-lvl-nav__col-link inverted-underline"
                  :data-test-id="`three-lvl-nav-item-${columnIndex}-${navItemIndex}`"
                  @keydown.tab.exact="
                    slotProps.tab(isLastMenuItem(slotProps.parentIndex, columnIndex, navItemIndex))
                  "
                  @click="slotProps.navClick(navItem)"
                >
                  {{ renderLinkText(navItem) }}
                </component>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </template>
  </main-header>
</template>

<script setup>
import { computed } from 'vue';
import MainHeader from '../MainHeader/MainHeader.vue';
import { useI18n } from 'vue-i18n';
import { getLinkBindings, getLinkTag } from '../../utils/linkTransformer';

const { t } = useI18n();

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const filteredMenu = computed(() => {
  return props.fields?.navItems.map((lvl1Item) => {
    if (!lvl1Item.fields.subItems.length) {
      return [];
    }

    // 1st columnn, Explore 1lvl Link + 2nd lvl links without link which has subItems
    const explore1lvlItem = lvl1Item.fields.link?.value.href
      ? {
          ...lvl1Item,
          exploreLink: true,
          fields: {
            ...lvl1Item.fields,
            subItems: [],
          },
        }
      : null;

    // next columnns, Explore 2lvl Link + 3rd lvl links
    const nextColumns = lvl1Item.fields?.subItems
      ?.filter((lvl2Item) => lvl2Item.fields?.subItems.length)
      .map((lvl2Item) => {
        const explore2lvlItem = lvl2Item.fields.link?.value.href
          ? {
              ...lvl2Item,
              exploreLink: true,
              fields: {
                ...lvl2Item.fields,
                subItems: [],
              },
            }
          : null;

        return [explore2lvlItem, ...lvl2Item.fields.subItems].filter(Boolean);
      });
    return [
      [
        explore1lvlItem,
        ...lvl1Item.fields.subItems.filter((lvl2Item) => !lvl2Item.fields.subItems?.length),
      ].filter(Boolean),
      ...nextColumns,
    ].filter((arr) => arr.length);
  });
});

const selectedMenu = (parentIndex) => {
  return filteredMenu.value[parentIndex];
};

const renderLinkText = (navItem) => {
  const linkText = `${navItem.exploreLink ? t('navigation-explore') : ''} ${
    navItem.fields.title?.value || navItem.fields.link?.value.text
  }`;

  return linkText.trim();
};

const isLastMenuItem = (parentIndex, columnIndex, navItemIndex) => {
  return (
    columnIndex === selectedMenu(parentIndex).length - 1 &&
    navItemIndex === selectedMenu(parentIndex)[columnIndex].length - 1
  );
};
</script>

<style lang="scss">
@import './scss/ThreeLevelMainHeader.scss';
</style>
