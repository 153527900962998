<template>
  <section class="contact-us" :class="classes">
    <div class="container">
      <div class="row contact-us__wrapper">
        <div v-if="isMobile && headerVisible" class="contact-us__header">
          <sc-text
            :tag="params.headingType ?? 'h1'"
            class="contact-us__title"
            :field="fields.title"
          />
          <sc-rich-text tag="p" class="contact-us__description" :field="fields.description" />
        </div>
        <div class="contact-us__info-column">
          <div v-if="!isMobile" class="contact-us__header">
            <sc-text
              :tag="params.headingType ?? 'h1'"
              class="contact-us__title"
              :field="fields.title"
            />
            <sc-rich-text tag="p" class="contact-us__description" :field="fields.description" />
          </div>
          <div ref="tiles" class="contact-us__tiles" tabindex="-1">
            <div
              v-if="fields.openingHours?.value?.length || fields.servingHours?.value?.length"
              class="contact-us-opening-hours"
              :class="{
                ...detectComponentVariant(variantedComponents.OPENING_HOURS),
              }"
            >
              <opening-hours
                v-if="fields.openingHours?.value?.length"
                :title="t('opening-hours')"
                :icon="'Clock'"
                :opening-hours="fields.openingHours"
                :heading-tag="openingHoursHeadingTag"
              />
              <opening-hours
                v-if="fields.servingHours?.value?.length"
                :title="t('serving-hours')"
                :icon="'Cutlry'"
                :opening-hours="fields.servingHours"
                :heading-tag="openingHoursHeadingTag"
              />
            </div>
            <div
              v-if="
                fields.advancedNoticeOpeningHours?.value?.length ||
                fields.advancedNoticeServingHours?.value?.length
              "
              class="contact-us-special-hours"
            >
              <h2 class="contact-us-special-hours__heading">
                {{ t('festive-opening-hours') }}
              </h2>
              <sc-text :field="fields.additionalLabel" class="" tag="p" />
              <base-button
                class="contact-us-special-hours__btn"
                :aria-expanded="areFestiveHoursExpanded"
                :label="areFestiveHoursExpanded ? t('hide-festive-hours') : t('view-festive-hours')"
                variant="secondary"
                icon="icon-Down"
                @click="toggleFestiveHours"
              />
              <div
                class="contact-us-special-hours__accordion"
                :class="{
                  ...detectComponentVariant(variantedComponents.OPENING_HOURS),
                }"
                :aria-hidden="!areFestiveHoursExpanded"
              >
                <opening-hours
                  v-if="fields.advancedNoticeOpeningHours?.value?.length"
                  :title="t('special-hours-advanced-notice-festive-opening-hours')"
                  :icon="'Clock'"
                  :opening-hours="fields.advancedNoticeOpeningHours"
                  :first-bolded="false"
                />
                <opening-hours
                  v-if="fields.advancedNoticeServingHours?.value?.length"
                  :title="t('special-hours-advanced-notice-kitchen-hours')"
                  :icon="'Cutlry'"
                  :opening-hours="fields.advancedNoticeServingHours"
                  :first-bolded="false"
                />
              </div>
            </div>
            <div v-if="fields.venuePhoneNumber?.value" class="contact-us-phone contact-us__tile">
              <span class="contact-us__heading font-s">
                <i class="icon icon-Phone" aria-hidden="true"></i> {{ $t('call-us') }}
              </span>
              <a
                class="contact-us-phone__number inverted-underline"
                :href="callUsHref"
                @click="handleClickContact('get_phone_number', callUsHref)"
              >
                {{ fields.venuePhoneNumber?.value }}
              </a>
            </div>
            <div v-if="fields.address?.value?.length" class="contact-us-location contact-us__tile">
              <span class="contact-us__heading font-s">
                <i class="icon icon-Location" aria-hidden="true"></i>
                {{ $t('location') }}
              </span>
              <address class="contact-us-location__address">
                <template
                  v-for="(line, index) in fields.address?.value"
                  :key="`contact-us-address-${index}`"
                >
                  <span>{{ line }}</span>
                  <br v-if="index < fields.address?.value?.length - 1" />
                </template>
              </address>
              <a
                v-if="!!mapLink"
                :href="mapLink"
                class="contact-us-location__link"
                target="_blank"
                rel="noopener noreferrer"
                @click="handleClickContact('get_location', mapLink)"
              >
                {{ $t('get-directions') }}
              </a>
            </div>
            <ul v-if="fields.socials?.value?.length" class="contact-us-socials">
              <li
                v-for="(social, index) in fields.socials.value"
                :key="index"
                class="contact-us-socials__item"
              >
                <a
                  :href="social.href"
                  class="contact-us-socials__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  :aria-label="social.title"
                  :data-test-social="social.type"
                  @click="handleClickContact(social.type.split('-')?.[1] || '', social.href)"
                >
                  <i class="icon" :class="social.type" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="isContactFormVisible">
          <div
            class="contact-us-form"
            :class="{
              ...detectComponentVariant(variantedComponents.CONTACT_US),
            }"
          >
            <template v-if="rendering?.placeholders['contact-us-form']?.length">
              <sc-placeholder name="contact-us-form" :rendering="rendering" />
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import {
  isEditorActive,
  Text as ScText,
  RichText as ScRichText,
  Placeholder as ScPlaceholder,
} from '@sitecore-jss/sitecore-jss-vue';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { useI18n } from 'vue-i18n';
import OpeningHours from '../2_elements/OpeningHoursItem/OpeningHoursItem.vue';
import BaseButton from '../2_elements/BaseButton/BaseButton.vue';
import useMedia from '../../utils/useMedia';
import { useRegisterDataLayerAction } from '../../data-layer/helpers/registerDataLayer';
import { useDetectVariantByBrand, variantedComponents } from '../../utils/componentVariants';

const FESTIVE_HOURS_ANCHOR = '#festive-opening-hours';

const detectComponentVariant = useDetectVariantByBrand();

const { t } = useI18n();

const { isMedia: isMobile } = useMedia('(max-width: 767px)');

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const tiles = ref(null);
const areFestiveHoursExpanded = ref(false);

const isContactFormVisible =
  (!isServer() && isEditorActive()) || props.rendering?.placeholders['contact-us-form']?.length;
const headerVisible = computed(() => props.fields.title?.value || props.fields.description?.value);
const openingHoursHeadingTag = computed(() => (props.params.headingType == 'h2' ? 'h3' : 'h2'));
const classes = computed(() => [!isContactFormVisible && 'contact-us--no-form']);

const callUsHref = computed(() => `tel:${props.fields.venuePhoneNumber?.value}`);
const mapLink = computed(() => {
  const latitudeValue = props.fields.venueLatitude?.value;
  const longitudeValue = props.fields.venueLongitude?.value;

  return (
    !!(latitudeValue && longitudeValue) &&
    `https://www.google.com/maps?q=${latitudeValue},${longitudeValue}`
  );
});

const toggleFestiveHours = () => {
  areFestiveHoursExpanded.value = !areFestiveHoursExpanded.value;
};

const registerDataLayerAction = useRegisterDataLayerAction();

const handleClickContact = (category, destination) => {
  registerDataLayerAction({
    event_name: 'click_link',
    link_text: category,
    intent: category,
    link_destination: destination,
    event_location: 'body',
    component: 'contact_us',
  });
};

const scrollToTiles = () => {
  tiles.value.focus();
  setTimeout(() => tiles.value.scrollIntoView({ behavior: 'smooth' }), 300);
};

onMounted(() => {
  if (window.location.hash === FESTIVE_HOURS_ANCHOR) scrollToTiles();
});
</script>

<style lang="scss">
@import './scss/ContactUs.scss';
</style>
