<template>
  <section
    v-if="$jss.sitecoreContext().pageEditing || hasEnoughData"
    id="gallery"
    class="gallery-base"
    :class="brandClasses"
  >
    <div class="container">
      <div v-if="hasEnoughData" ref="galleryBaseRef" class="gallery-base__wrapper">
        <span
          class="gallery-base__counter"
          :aria-label="`${currentSlide + 1} out of ${fields?.slides?.value?.length}`"
          role="img"
        >
          {{ `${currentSlide + 1} / ${fields?.slides?.value?.length}` }}
        </span>
        <button
          class="gallery-base__nav gallery-base__nav--prev"
          :aria-label="t('slider-previous-button')"
          role="button"
          @click="onClickPrevSlideArr"
        >
          <i class="icon" :class="backIconClasses" aria-hidden="true"></i>
        </button>
        <button
          class="gallery-base__nav gallery-base__nav--next"
          :aria-label="t('slider-next-button')"
          role="button"
          @click="onClickNextSlideArr"
        >
          <i class="icon" :class="forwardIconClasses" aria-hidden="true"></i>
        </button>
        <swiper
          :effect="'fade'"
          :thumbs="{ swiper: thumbsSwiper }"
          v-bind="swiperOptions"
          class="gallery-base__slider"
          @slide-change="onSlideChange"
          @swiper="onSwiper"
        >
          <template v-for="(slide, index) in filteredSlides" :key="`gitem-${index}`">
            <swiper-slide>
              <picture>
                <source
                  :srcset="slide.src[3]"
                  media="(min-width: 1024px)"
                  :width="slide.width"
                  :height="IMAGE_SIZE"
                />
                <source
                  :srcset="slide.src[2]"
                  media="(min-width: 768px)"
                  :width="slide.width"
                  :height="IMAGE_SIZE"
                />
                <source
                  :srcset="slide.src[1]"
                  media="(min-width: 431px)"
                  :width="slide.width"
                  :height="IMAGE_SIZE"
                />
                <img
                  :src="slide.src[0]"
                  :alt="slide.alt"
                  :width="slide.width"
                  :height="IMAGE_SIZE"
                  :loading="componentRouteIndex > 1 || !!index ? 'lazy' : null"
                />
              </picture>
            </swiper-slide>
          </template>
        </swiper>
        <div class="gallery-base__thumbs-container">
          <div class="gallery-base__thumbs-wrapper">
            <swiper
              class="gallery-base__thumbs"
              :slides-per-view="slidesPerView"
              :class="{
                'gallery-base__thumbs--editor': $jss.sitecoreContext().pageEditing,
              }"
              :center-insufficient-slides="true"
              :slide-to-clicked-slide="true"
              :watch-slides-progress="true"
              role="listbox"
              aria-label="Thumbnail gallery"
              @swiper="setThumbsSwiper"
              @resize="calculateSlidesPerView"
            >
              <template v-for="(slide, index) in fields?.slides?.value" :key="`gthumb-${index}`">
                <swiper-slide>
                  <div class="gallery-base__thumb-wrapper">
                    <div class="gallery-base__thumb">
                      <button
                        role="option"
                        :aria-label="`${slide.alt} - slide ${index + 1} of ${
                          fields?.slides?.value?.length
                        }`"
                        :aria-selected="currentSlide === index"
                        @keyup.enter="swiperCarousel.slideTo(index)"
                        @click="swiperCarousel.slideTo(index)"
                      >
                        <base-image
                          :desktop-url="slide.src"
                          :desktop-alt="slide.alt"
                          :custom-params="{
                            mobileS: 60,
                            mobile: 60,
                            tablet: 60,
                            desktop: 60,
                          }"
                          lazy-loading="lazy"
                          fallback-param="s"
                        />
                      </button>
                    </div>
                  </div>
                </swiper-slide>
              </template>
            </swiper>
          </div>
        </div>
      </div>
      <span v-else-if="$jss.sitecoreContext().pageEditing"
        >At least 2 slides are required to render gallery</span
      >
    </div>
  </section>
</template>

<script setup>
import { ref, computed, inject, nextTick } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectFade, Navigation, Thumbs, FreeMode } from 'swiper/modules';

import BaseImage from '../2_elements/BaseImage/BaseImage.vue';
import { useI18n } from 'vue-i18n';
import useMedia from '../../utils/useMedia';
import { useRegisterDataLayerAction } from '../../data-layer/helpers/registerDataLayer';
import { useRouteData } from '../../composables/useRouteData';
import { useSources } from '../../composables/useSources';

import 'swiper/scss';
import 'swiper/scss/effect-fade';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

const { t } = useI18n();

const jssStore = inject('jssStore');
const brandName = jssStore?.sitecoreContext()?.brandName;

const MIN_COUNT_OF_SLIDES = 2;
const MOBILE_SLIDER_HEIGHT = 507;
const TABLET_SLIDER_HEIGHT = 595;
const DESKTOP_SLIDER_HEIGHT = 690;

const swiperCarousel = ref(null);
const thumbsSwiper = ref(null);
const galleryBaseRef = ref(null);
const currentSlide = ref(0);
const slidesPerView = ref('auto');

const { isMedia: isTablet } = useMedia('(min-width: 768px)');
const { isMedia: isDesktop } = useMedia('(min-width: 1024px)');

const DESKTOP_OR_TABLE_HEIGHT = isDesktop.value ? DESKTOP_SLIDER_HEIGHT : TABLET_SLIDER_HEIGHT;

const IMAGE_SIZE =
  !isTablet.value && !isDesktop.value ? MOBILE_SLIDER_HEIGHT : DESKTOP_OR_TABLE_HEIGHT;

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const { componentRouteIndex } = useRouteData(props.rendering?.uid);
const swiperOptions = {
  modules: [EffectFade, FreeMode, Navigation, Thumbs],
  loop: true,
  lazyPreloadPrevNext: componentRouteIndex.value > 1 ? 0 : 2,
};

const SLIDE_SIZE = 83;

const calculateSlidesPerView = () => {
  nextTick(() => {
    slidesPerView.value = Math.floor(thumbsSwiper.value?.width / SLIDE_SIZE);
  });
};

const filteredSlides = computed(() => {
  return props.fields.slides?.value.map((s) => {
    const { getSources } = useSources({
      componentName: 'GalleryBase',
      desktopUrl: s.src,
    });

    return {
      ...s,
      src: getSources.value,
    };
  });
});

const hasEnoughData = computed(() => props.fields?.slides?.value?.length >= MIN_COUNT_OF_SLIDES);

const brandClasses = computed(() => [
  brandName === 'ChefBrewer' && 'bottom-green-border top-green-border',
]);

const backIconClasses = computed(() => [
  brandName === 'Kinsmith' ? 'icon-Arrow-Back-2' : 'icon-Back',
]);

const forwardIconClasses = computed(() => [
  brandName === 'Kinsmith' ? 'icon-Arrow-Forward-2' : 'icon-Forward',
]);

const onSwiper = (swiper) => {
  swiperCarousel.value = swiper;
};

const setThumbsSwiper = (swiper) => {
  thumbsSwiper.value = swiper;
  calculateSlidesPerView();
};

const onClickPrevSlideArr = () => {
  const nextSlideIndex = swiperCarousel.value.isBeginning
    ? props.fields.slides.value.length - 1
    : swiperCarousel.value.activeIndex - 1;
  swiperCarousel.value.slideTo(nextSlideIndex);
};

const onClickNextSlideArr = () => {
  const nextSlideIndex = swiperCarousel.value.isEnd ? 0 : swiperCarousel.value.activeIndex + 1;
  swiperCarousel.value.slideTo(nextSlideIndex);
};

const onSlideChange = (instance) => {
  if (currentSlide.value === instance.realIndex) return;
  handleRegisterDataLayerGallery(
    currentSlide.value <= instance.realIndex ? 'forward_gallery' : 'backward_gallery'
  );

  currentSlide.value = instance.realIndex;
};

const registerDataLayerAction = useRegisterDataLayerAction();

const handleRegisterDataLayerGallery = (event) => {
  registerDataLayerAction({
    event_name: event,
    component: 'gallery_base',
  });
};
</script>

<style lang="scss">
@import './scss/GalleryBase.scss';
</style>
