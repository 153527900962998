<template>
  <multipurpose-image-banner
    class="mib--manager-card"
    :fields="mappedFields"
    :rendering="rendering"
    :params="params"
  />
</template>

<script setup>
import { computed } from 'vue';
import MultipurposeImageBanner from '../MultipurposeImageBanner/MultipurposeImageBanner.vue';

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const mappedFields = computed(() => {
  return {
    heading: props.fields?.businessDevelopmentManagerName,
    subheading: props.fields?.businessDevelopmentManagerJobTitle,
    description: props.fields?.summaryPubComments?.value
      ? { value: `‘${props.fields.summaryPubComments.value}’` }
      : null,
    imageDesktop: props.fields?.businessDevelopmentManagerPhoto,
  };
});
</script>
