<template>
  <div class="sr-only" aria-live="assertive" aria-atomic="true" v-text="announcementText"></div>
</template>

<script setup>
import { ref, watch, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

let clearAnnouncementTimeout = null;
const announcementText = ref(null);

const props = defineProps({
  isRouteLoading: {
    type: Boolean,
  },
  title: {
    type: String,
  },
});

watch(
  () => props.isRouteLoading,
  () => {
    if (props.isRouteLoading) {
      announcementText.value = t('page-loading');
      clearTimeout(clearAnnouncementTimeout);
    } else {
      announcementText.value = `${props.title} ${t('page-loaded')}`;
      document.getElementById('root').focus();
      clearAnnouncementTimeout = setTimeout(() => {
        announcementText.value = null;
      }, 1000);
    }
  }
);

onBeforeUnmount(() => {
  clearTimeout(clearAnnouncementTimeout);
});
</script>
