<template>
  <zone-wrapper
    v-if="fields?.scriptUrl?.value"
    :api-settings="bookSpaceAPISettings"
    :script-url="fields.scriptUrl.value"
    window-key="space-booking-settings-embed"
  >
    <div id="space-booking-widget-embed" class="book-space-widget">
      <div class="booking-app__inner">
        <noscript> The space booking requires JavaScript to be enabled. </noscript>
      </div>
    </div>
  </zone-wrapper>
</template>

<script setup>
import { inject } from 'vue';
import ZoneWrapper from '../ZoneWrapper/ZoneWrapper.vue';

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const jssStore = inject('jssStore');

const bookSpaceAPISettings = {
  env: props?.fields?.env?.value,
  apiUrl: props?.fields?.scriptApiUrl?.value,
  bookingGTMId: props?.fields?.bookingGtmId?.value,
  bookingGTMAuth: props?.fields?.bookingGtmAuth?.value,
  bookingGTMEnv: props?.fields?.bookingGtmEnv?.value,
  softOpt: props?.fields?.bookingSoftOpt?.value,
  termsAndConditionsUrl: props?.fields?.termsAndConditionsUrl?.value?.href,
  phone: props?.fields?.phone?.value,
  areaBookings: true,
  areaEnquiries: !!props.fields?.areaEnquiries?.value,
  pathUrl: props.fields?.pathUrl?.value,
  venueId: jssStore?.sitecoreContext()?.venueId?.toString().padStart(4, '0'),
  brand: jssStore?.sitecoreContext()?.brandName,
  venueName: jssStore?.sitecoreContext()?.venueName,
  venueImage: props.fields?.venueImage?.value?.src ?? '',
  segment: jssStore?.sitecoreContext()?.segment,
  worldpayHelper:
    window?.location.protocol + '//' + window?.location.host + props?.fields?.worldpayPath?.value,
  enquiryFormOnly: false,
  spaceImages: {},
};
</script>

<style lang="scss">
@import './scss/BookSpaceWidget.scss';
</style>
