<template>
  <div class="base-nav-list" :class="mainClasses">
    <div class="container">
      <div
        class="base-nav-list__container"
        :class="{
          'base-nav-list__container--no-underline': noUnderline,
        }"
        :aria-live="wrapperRole === 'alert' ? 'polite' : undefined"
      >
        <div
          class="base-nav-list__scroll-btn-wrapper"
          :class="{
            'base-nav-list__scroll-btn-wrapper--visible': scrollLeftVisible,
          }"
        >
          <button
            class="base-nav-list__scroll-btn"
            :aria-label="t('subnavigation-scroll-left')"
            @click="() => onScrollIconClick(-1)"
          >
            <i class="icon-Back base-nav-list__scroll-btn-icon" aria-hidden="true"></i>
          </button>
        </div>

        <div ref="navRef" class="base-nav-list__list" role="tablist" @scroll="onScroll">
          <template v-for="(item, index) in filteredItems" :key="item?.id ?? `base-nav-${index}`">
            <router-link
              v-if="variant === 'links'"
              :ref="(el) => addRef(el, index)"
              class="base-nav-list__item"
              role="tab"
              :aria-selected="isSelected(item)"
              :class="{
                'base-nav-list__item--selected': isSelected(item),
                'base-nav-list__item--second-level': secondLevel,
                'no-underline': noUnderline,
              }"
              :to="item.href"
              :data-test-id="`nav-list-link-${index}`"
              :data-text="item.title"
              @click="handleSubNavigationClick(item)"
            >
              {{ item.title }}
            </router-link>
            <button
              v-else-if="variant === 'buttons'"
              :ref="(el) => addRef(el, index)"
              role="tab"
              :aria-selected="isSelected(item)"
              class="base-nav-list__item"
              :class="{
                'base-nav-list__item--selected': isSelected(item),
                'base-nav-list__item--second-level': secondLevel,
                'no-underline': noUnderline,
              }"
              :data-test-id="`nav-list-button-${index}`"
              :data-text="item.label"
              @click="
                () => {
                  callback(item.id);
                  handleSubNavigationClick(item);
                }
              "
            >
              {{ item.label }}
            </button>
          </template>
        </div>

        <div
          class="base-nav-list__scroll-btn-wrapper"
          :class="{
            'base-nav-list__scroll-btn-wrapper--visible': scrollRightVisible,
          }"
        >
          <button
            class="base-nav-list__scroll-btn"
            :aria-label="t('subnavigation-scroll-right')"
            @click="() => onScrollIconClick(1)"
          >
            <i class="icon-Forward base-nav-list__scroll-btn-icon" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect, computed, onBeforeUpdate } from 'vue';
import { useI18n } from 'vue-i18n';
import useNavScroll from './utils/useNavScroll';

const { t } = useI18n();

const props = defineProps({
  items: {
    type: Object,
  },
  variant: {
    type: String,
    default: 'links',
    validator: (value) => {
      return ['links', 'buttons'].includes(value);
    },
  },
  callback: {
    type: Function,
    default: () => null,
  },
  selectedItemId: [String, Number],
  secondLevel: {
    type: Boolean,
    default: false,
  },
  handleSubNavigationClick: {
    type: Function,
    default: () => null,
  },
  wrapperRole: {
    type: String,
    default: () => null,
  },
  navPosition: {
    type: String,
    default: () => null,
    validator: (value) => {
      return ['center'].includes(value);
    },
  },
  underline: {
    type: Boolean,
    default: true,
  },
});

const mainClasses = computed(() => props.navPosition && `base-nav-list--${props.navPosition}`);
const noUnderline = computed(() => props.secondLevel || !props.underline);

const filteredItems = computed(() =>
  props.variant !== 'buttons' ? props.items : props.items.filter((item) => !item.hidden)
);

const navRef = ref(null);
const navItemRefs = ref([]);
const selectedElIndex = ref(null);

const { onScroll, onScrollIconClick, scrollLeftVisible, scrollRightVisible } = useNavScroll(
  selectedElIndex,
  navRef,
  navItemRefs,
  props.variant
);

const isSelected = (item) => {
  if (props.variant === 'links') return item.isActive;
  return props.selectedItemId === item.id;
};

const addRef = (el, index) => {
  navItemRefs.value[index] = el;
};

onBeforeUpdate(() => {
  navItemRefs.value = [];
});

watchEffect(() => {
  if (props.variant === 'links') {
    let index = filteredItems.value.findIndex((el) => el?.isActive);
    selectedElIndex.value = index === -1 ? null : index;
  } else
    selectedElIndex.value = filteredItems.value.findIndex((el) => el.id === props.selectedItemId);
});
</script>

<style lang="scss">
@import './scss/BaseNavList.scss';
</style>
