import { isServer } from '@sitecore-jss/sitecore-jss';

import { ECOMMERCE_DATA_LAYER_ITEM, useDataLayerDefaultProps } from './defaultProps';
import { fillEmptyValues } from './utils';

export const useRegisterEcommerceDataLayerAction = () => {
  const defaultProps = useDataLayerDefaultProps(ECOMMERCE_DATA_LAYER_ITEM);

  return (data) => {
    if (typeof window !== 'undefined' && !isServer()) {
      window.dataLayer = window?.dataLayer || [];

      let normalizedData = fillEmptyValues({ ...data, ...defaultProps?.defaultGlobalProps });

      if (normalizedData?.ecommerce?.items?.length) {
        normalizedData.ecommerce.items = normalizedData.ecommerce.items.map((item) =>
          fillEmptyValues({ ...item, ...defaultProps?.defaultItemProps })
        );
      }

      window.dataLayer.push(normalizedData);

      return window.dataLayer;
    }
  };
};
