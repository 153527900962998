<template>
  <div ref="bar" :tabindex="isOpen ? 0 : -1" :class="['iframe-bar', isOpen && 'iframe-bar--open']">
    <span class="iframe-bar__backdrop" @click="onCloseClick()"></span>
    <Transition name="iframe-bar">
      <div v-show="isOpen" class="iframe-bar__bar" @keydown.esc="onCloseClick()">
        <div class="iframe-bar__header">
          <h2 class="iframe-bar__heading font-m">{{ header }}</h2>
          <button class="iframe-bar__close-btn" aria-label="Close iframe" @click="onCloseClick()">
            <i class="icon icon-Close" aria-hidden="true"></i>
          </button>
        </div>
        <div class="iframe-bar__content">
          <iframe
            :src="props.src"
            :title="props.header"
            width="100%"
            tabindex="0"
            allow="fullscreen"
            loading="lazy"
            @load="onIframeLoaded()"
          ></iframe>
          <loading-spinner v-if="isLoading" />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import LoadingSpinner from '../../../../components/2_elements/LoadingSpinner/LoadingSpinner.vue';
import { useAppStore } from '../../../../stores/appStore';

const appStore = useAppStore();

const emit = defineEmits(['onClose']);
const props = defineProps({
  src: {
    type: String,
    default: '',
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  header: {
    type: String,
    default: '',
  },
});

const TRANSITION_TIMEOUT = 200;
const bar = ref(null);
const isLoading = ref(true);

const { activate, deactivate } = useFocusTrap(bar);

const openIframeBar = () => {
  appStore.blockScrolling();
  setTimeout(() => activate(), TRANSITION_TIMEOUT);
};

const onCloseClick = () => {
  emit('onClose');
};

const onIframeLoaded = () => {
  isLoading.value = false;
};

const closeIframeBar = () => {
  appStore.unblockScrolling();
  setTimeout(() => deactivate(), TRANSITION_TIMEOUT);
};

watch(
  () => props.isOpen,
  () => {
    if (props.isOpen) openIframeBar();
    else closeIframeBar();
  }
);
</script>

<style lang="scss">
@import './scss/IframeBar.scss';
</style>
