import timeoutDelay from './timeoutDelay';

const retryWithDelay = async (fn, attempts = 3, interval = 500, exponentialBackoff = false) => {
  try {
    await fn();
  } catch (error) {
    if (!(attempts - 1)) {
      return Promise.reject(error);
    }
    await timeoutDelay(interval);

    return retryWithDelay(
      fn,
      attempts - 1,
      exponentialBackoff ? interval * 2 : interval,
      exponentialBackoff
    );
  }
};

export default retryWithDelay;
