<template>
  <div
    v-if="fields.isVisible"
    class="fixed-promo-message"
    :class="detectComponentVariantByBrandAndTheme(variantedComponents.FIXED_PROMO_MESSAGE)"
  >
    <div v-if="fields.icon?.value?.src" class="fixed-promo-message__img">
      <base-image
        :desktop-url="fields.icon.value.src"
        :desktop-alt="fields.icon.value.alt"
        :custom-params="{ desktop: 20, tablet: 20, mobile: 20, mobileS: 20 }"
        fallback-param="h"
      />
    </div>
    <div class="fixed-promo-message__text">
      <base-link
        v-if="fields.link?.value?.href"
        :field="{
          value: {
            ...fields.link.value,
            text: fields.message?.value,
          },
        }"
      />
      <div v-else>
        {{ fields.message?.value }}
      </div>
    </div>
    <button
      v-if="!!fields.isClosable?.value"
      class="fixed-promo-message__close-btn"
      :aria-label="t('close')"
      @click="onCloseBtnClick(fields.id)"
    >
      <i class="icon icon-Close" aria-hidden="true"></i>
    </button>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import BaseImage from '../../2_elements/BaseImage/BaseImage.vue';
import BaseLink from '../../2_elements/BaseLink/BaseLink.vue';
import {
  useDetectVariantByBrandAndTheme,
  variantedComponents,
} from '../../../utils/componentVariants';

const detectComponentVariantByBrandAndTheme = useDetectVariantByBrandAndTheme();

const { t } = useI18n();

defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['closeMsg']);
const onCloseBtnClick = (id) => {
  emit('closeMsg', { id });
};
</script>

<style lang="scss">
@import './scss/FixedPromoMessageItem.scss';
</style>
