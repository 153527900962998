import { inject, computed } from 'vue';
import { isServer } from '@sitecore-jss/sitecore-jss';

export function useRouteData(uid) {
  const jssStore = inject('jssStore');
  const mainData = jssStore
    .routeData()
    ?.placeholders?.['jss-main']?.filter(
      (c) =>
        !c.placeholders ||
        c.placeholders[Object.keys(c.placeholders)].filter((pi) => pi.fields).length
    );
  const topMainComponentData = mainData?.[0];

  const componentName = computed(() => mainData?.find((c) => c?.uid === uid)?.componentName);
  const componentRouteIndex = computed(() =>
    !isServer() && window?.IS_STORYBOOK ? 0 : mainData?.findIndex((c) => c.uid === uid)
  );
  const componentItemIndex = computed(() =>
    mainData
      ?.filter((c) => c.componentName === componentName?.value)
      .findIndex((c) => c.uid === uid)
  );

  return { componentRouteIndex, componentItemIndex, topMainComponentData };
}
