import { RestDictionaryService } from '@sitecore-jss/sitecore-jss-vue';
import config from '../temp/config';

export class DictionaryServiceFactory {
  create(apiHost, venueAppName) {
    return new RestDictionaryService({
      apiHost: apiHost ?? config.sitecoreApiHost,
      apiKey: config.sitecoreApiKey,
      siteName: venueAppName ?? config.jssAppName,
    });
  }
}

export const dictionaryServiceFactory = new DictionaryServiceFactory();
