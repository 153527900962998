import { ref, inject } from 'vue';
import { defineStore } from 'pinia';
import retryWithDelay from '../utils/retryWithDelay';

export const useContentCardsStore = defineStore('content-cards', () => {
  const isFeedSubscribed = ref(false);
  const contentCards = ref(null);
  const feedLastUpdated = ref(null);

  const jssStore = inject('jssStore');
  const brandName = jssStore.sitecoreContext()?.brandName;
  const contentCardsEnabled = jssStore.sitecoreContext()?.enableContentCard;
  const siteVirtualFolder = jssStore.sitecoreContext()?.siteVirtualFolder ?? '/';
  const venueId = jssStore.sitecoreContext()?.venueId;

  const getHeroCarouselContentCards = () => {
    const pageTemplateName = jssStore?.routeData()?.templateName;

    return (
      contentCards.value?.length &&
      parseHeroCarouselCard(
        contentCards.value.filter(
          (card) =>
            card.extras?.['cc_component'] === 'HeroCarousel' &&
            card.extras?.['cc_page_template'] === pageTemplateName &&
            card.extras['cc_control'] === 'no'
        ),
        siteVirtualFolder
      )
    );
  };

  const logContentCardDisplay = (cardId) => {
    braze.logContentCardImpressions(contentCards.value.filter((card) => card.id === cardId));
  };

  const logContentCardClick = (cardId) => {
    braze.logContentCardClick(contentCards.value.find((card) => card.id === cardId));
  };

  const subscribeContentCardFeed = () => {
    if (isFeedSubscribed.value || !contentCardsEnabled) return;

    retryWithDelay(
      () => {
        if (window?.braze) {
          braze.subscribeToContentCardsUpdates(onContentCardsUpdate);

          isFeedSubscribed.value = true;

          updateContentCardFeed();
        } else if (
          !window?.Cookiebot?.consent?.marketing ||
          !window?.Cookiebot?.consent?.statistics
        ) {
          window.addEventListener('CookiebotOnAccept', subscribeContentCardFeed);
        } else {
          throw new Error('Braze SDK unavailable');
        }
      },
      6,
      500,
      true
    ).catch((error) => console.warn(error));
  };

  const onContentCardsUpdate = ({ cards, lastUpdated }) => {
    if (feedLastUpdated.value === lastUpdated.getTime()) return;

    const validContentCards = cards.filter(
      (card) => card.extras['cc_brand'] === brandName && card.extras['cc_venueid'] === venueId
    );

    const newControlCards = validContentCards.filter(
      (card) =>
        card.extras['cc_control'] === 'yes' &&
        !contentCards.value?.some((existingCard) => existingCard.id === card.id)
    );

    if (newControlCards.length) {
      braze.logContentCardImpressions(newControlCards);
    }

    feedLastUpdated.value = lastUpdated.getTime();
    contentCards.value = validContentCards;
  };

  const updateContentCardFeed = () => {
    braze.requestContentCardsRefresh();

    setTimeout(() => updateContentCardFeed(), 60000);
  };

  return {
    contentCards,
    isFeedSubscribed,
    getHeroCarouselContentCards,
    subscribeContentCardFeed,
    logContentCardDisplay,
    logContentCardClick,
  };
});

const parseHeroCarouselCard = (cards, baseUrl) =>
  cards.map((card) => ({
    componentName: 'HeroCarouselItem',
    contentCard: true,
    fields: {
      contentCardId: card.id,
      subtitle: { value: card?.description },
      title: { value: card?.title },
      imageDesktop: { value: { src: card?.imageUrl, alt: card.extras?.['cc_img_alt_text'] ?? '' } },
      showTransparentLayer: { value: true },
      link: {
        value: {
          href: card?.url && (baseUrl + card.url).replace('//', '/'),
          text: card?.linkText,
          target: '_self',
          linktype: 'internal',
        },
      },
    },
  }));
