<template>
  <div
    class="main-header"
    :class="{
      'main-header--logo-position-left': logoPositionLeft,
    }"
  >
    <div class="main-header__top container">
      <mobile-menu
        :class="{ ...detectComponentVariant(variantedComponents.MOBILE_MENU) }"
        :fields="fields"
        :booking-options="bookingOptions"
        :handle-booking-click="handleBookingClick"
        :handle-navigation-click="handleNavigationItemClick"
        @mobile-menu-open="headerCTARef?.closeBookingMenu()"
      />
      <div
        class="main-header__top-buttons"
        :class="{ ...detectComponentVariant(variantedComponents.MAIN_HEADER) }"
      >
        <div class="main-header__logo">
          <router-link
            :to="fields?.link?.value?.href"
            data-test-id="logo"
            class="main-header__link no-underline"
          >
            <img
              :src="fields?.logo?.value?.src"
              :width="fields.logo?.value?.width"
              :height="fields.logo?.value?.height"
              :alt="fields?.logo?.value?.alt"
              :title="fields?.link?.value?.text"
              fetchpriority="high"
            />
          </router-link>
        </div>
        <header-cta
          v-if="!logoPositionLeft || !isDesktop"
          :ref="(el) => setHeaderCtaRef(el, false)"
          v-bind="headerCTABinds"
        />
      </div>
    </div>
    <nav-bar
      ref="navBarRef"
      :menus="fields?.navItems"
      :handle-navigation-click="handleNavigationItemClick"
      :logo-position="fields?.logoPosition?.value"
      :three-lvl="threeLvl"
      @mega-nav-open="onMegaNavOpen"
      @mega-nav-close="onMegaNavClose"
    >
      <header-cta
        v-if="logoPositionLeft"
        :ref="(el) => setHeaderCtaRef(el, true)"
        v-bind="headerCTABinds"
      />
      <template #threeLvlNav="navBarProps">
        <slot
          name="threeLvlNav"
          :parent-index="navBarProps.selectedMenu"
          :nav-click="(item) => navBarProps.on.navClick(item)"
          :tab="(index, isLastMenuItem) => navBarProps.on.tab(index, isLastMenuItem)"
        ></slot>
      </template>
    </nav-bar>
  </div>
</template>

<script setup>
import { computed, ref, provide } from 'vue';
import { RouterLink } from 'vue-router';
import NavBar from './NavBar/NavBar.vue';
import useMedia from '../../utils/useMedia';
import { mainHeaderBookingDataLayer } from '../../data-layer/mainHeader';
import { useRegisterDataLayerAction } from '../../data-layer/helpers/registerDataLayer';
import {
  useDetectVariantByBrandAndTheme,
  variantedComponents,
} from '../../utils/componentVariants';
import MobileMenu from './MobileMenu/MobileMenu.vue';
import HeaderCta from './HeaderCta.vue';

const detectComponentVariant = useDetectVariantByBrandAndTheme();
const { isMedia: isDesktop } = useMedia('(min-width: 1024px)');

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
  threeLvl: {
    type: Boolean,
    default: () => false,
  },
});

provide('threeLvl', props.threeLvl);

const bookingOptionsData = {
  ctaBookTable: {
    key: 'book-table-cta',
    labelKey: 'book-table-cta-long',
    action: mainHeaderBookingDataLayer.actions.BOOK_A_TABLE,
    icon: 'icon-Cutlry2',
    linktype: 'internal',
  },
  ctaBookRoom: {
    key: 'book-room-cta',
    labelKey: 'book-room-cta-long',
    action: mainHeaderBookingDataLayer.actions.BOOK_A_ROOM,
    icon: 'icon-Bed',
    linktype: 'external',
  },
};

const bookingOptions = computed(() => setBookingOptions());
const logoPositionLeft = computed(() => props.fields?.logoPosition?.value == 'left');
const headerCTABinds = computed(() => ({
  ctaHeaderLink: props.fields?.ctaHeaderLink,
  ctaButtonTabIndex: ctaButtonTabIndex.value,
  bookingOptions: bookingOptions.value,
  handleBookingClick,
  onBookingMenuOpen: () => navBarRef.value?.closeMegaNav(),
}));

const navBarRef = ref(null);
const headerCTARef = ref(null);
const ctaButtonTabIndex = ref(null);

const setHeaderCtaRef = (el, isInNavBar) => {
  if (!el) return;
  if ((isInNavBar && isDesktop.value) || !isInNavBar) headerCTARef.value = el;
};

const onMegaNavOpen = () => {
  headerCTARef.value?.closeBookingMenu();
  ctaButtonTabIndex.value = -1;
};

const onMegaNavClose = () => {
  ctaButtonTabIndex.value = null;
};

const setBookingOptions = () => {
  const options = [];

  const ctaBookTable = pushBookingOption('ctaBookTable');
  if (ctaBookTable) options.push(ctaBookTable);

  const ctaBookRoom = pushBookingOption('ctaBookRoom');
  if (ctaBookRoom) options.push(ctaBookRoom);

  return options;
};

const pushBookingOption = (name) => {
  if (!props.fields?.[name]?.value?.href || !bookingOptionsData[name]) return;

  bookingOptionsData[name].data = props.fields[name].value;

  if (!bookingOptionsData[name].data.linktype)
    bookingOptionsData[name].data.linktype = bookingOptionsData[name].linktype;

  return bookingOptionsData[name];
};

const registerDataLayerAction = useRegisterDataLayerAction();

const handleBookingClick = (action) => {
  registerDataLayerAction({
    event_name: 'click_button',
    link_text: action,
    intent: action,
    component: 'main_header',
  });
};

const handleNavigationItemClick = (action) => {
  registerDataLayerAction({
    event_name: 'click_link',
    link_text: action,
    intent: 'navigation_interaction',
    component: 'main_header',
  });
};
</script>

<style lang="scss">
@import './scss/MainHeader.scss';
</style>
