<template>
  <div class="opening-hours-item">
    <button class="opening-hours-item__header" :aria-expanded="isExpanded" @click="onClick">
      <i v-if="icon" :class="icon" aria-hidden="true"></i>
      <sc-text
        :tag="headingTag"
        class="opening-hours-item__title font-s"
        :field="{ value: title }"
      />
      <i class="opening-hours-item__header-state-icon icon icon-Up" aria-hidden="true"></i>
    </button>

    <div class="opening-hours-item__container">
      <div
        v-for="(item, index) in openingHours?.value"
        :key="item.day + index"
        :class="[
          'opening-hours-item__item',
          index === 0 && firstBolded && 'opening-hours-item__item--bold',
          index !== 0 && !isExpanded && 'opening-hours-item__item--hidden',
        ]"
        tabindex="-1"
        :aria-hidden="index !== 0 && !isExpanded"
      >
        <div class="opening-hours-item__day-container">
          <div v-if="index === 0" class="opening-hours-item__day">
            <span>Today</span>
            <span class="opening-hours-item__day--today-abbr">
              {{ formatToday(item.day) }}
            </span>
          </div>
          <span v-else class="opening-hours-item__day">{{ item.day }}</span>
          <span v-if="item.occasionName" class="opening-hours-item__occasion">
            {{ item.occasionName }}
          </span>
        </div>
        <div class="opening-hours-item__hour-container">
          <span v-if="item.isClosed" class="opening-hours-item__hour"> {{ t('pub-closed') }}</span>
          <template v-else>
            <span v-for="(hour, i) in item.hours" :key="i" class="opening-hours-item__hour">
              {{ hour }}
            </span>
          </template>
        </div>
      </div>
      <div
        v-if="description"
        class="opening-hours-item__description font-s-copy"
        :class="!isExpanded && 'opening-hours-item__description--hidden'"
      >
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Text as ScText } from '@sitecore-jss/sitecore-jss-vue';

const { t } = useI18n();

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: '',
  },
  openingHours: {
    type: Object,
    default: () => ({ value: [] }),
  },
  description: {
    type: String,
  },
  firstBolded: {
    type: Boolean,
    default: true,
  },
  headingTag: {
    type: String,
    default: 'h3',
  },
});

const isExpanded = ref(false);

const icon = computed(() => (props.icon ? `icon icon-${props.icon}` : null));

const formatToday = (day) => ` (${day.slice(0, 3)})`;

const onClick = () => {
  isExpanded.value = !isExpanded.value;
};
</script>

<style lang="scss">
@import './scss/OpeningHoursItem.scss';
</style>
