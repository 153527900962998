<template>
  <component
    :is="componentType === 'iframe-link' ? IframeLink : componentType"
    :class="classes"
    :data-test-id="dataTestId"
    :tabindex="tabIndex"
    v-bind="componentBindings"
    :text="text || field.value?.text"
  >
    {{ text || field.value?.text }}
  </component>
</template>

<script setup>
import { computed } from 'vue';
import { getLinkTag } from '../../../utils/linkTransformer';
import IframeLink from '../../2_elements/IframeLink/IframeLink.vue';

const ANCHOR = 'anchor';
const INTERNAL_LINK = 'internal';

const componentType = computed(() => getLinkTag(props.field?.value?.linktype));

const componentBindings = computed(() => {
  const bindings = {};
  if (componentType.value !== 'iframe-link') {
    bindings.target = props.field?.value?.target || null;
    bindings.rel = props.field?.value?.target == '_blank' ? 'noopener noreferrer' : null;
    bindings.alt = props.field?.value?.alt || null;
    bindings.classes = props.field?.value?.classes || null;
  }

  let href = props.href || props.field?.value?.href;
  if (href && props.field?.value?.anchor && props.field?.value?.linktype !== ANCHOR)
    href += `#${props.field.value.anchor}`;

  if (props.field?.value?.linktype === INTERNAL_LINK) bindings.to = href;
  else bindings.href = href;
  return bindings;
});

const props = defineProps({
  field: {
    type: Object,
    default: () => ({}),
  },
  href: {
    type: String,
    default: () => null,
  },
  text: {
    type: String,
    default: () => null,
  },
  classes: {
    type: String,
    default: () => null,
  },
  dataTestId: {
    type: String,
    default: () => null,
  },
  tabIndex: {
    type: Number,
    default: () => null,
  },
});
</script>
