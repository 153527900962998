import { inject, computed } from 'vue';
import { isServer } from '@sitecore-jss/sitecore-jss';

export const DEFAULT_DATA_LAYER = 'DEFAULT_DATA_LAYER';
export const ECOMMERCE_DATA_LAYER_ITEM = 'ECOMMERCE_DATA_LAYER_ITEM';
export const VENUE_FINDER_DATA_LAYER = 'VENUE_FINDER_DATA_LAYER';

export const useDataLayerDefaultProps = (dataLayerType) => {
  const jssStore = inject('jssStore');
  const venueName = jssStore?.sitecoreContext()?.venueName;
  const venueId = jssStore?.sitecoreContext()?.venueId;
  const venueOrigin = jssStore?.sitecoreContext()?.venueOrigin ?? '';
  const itemPath = jssStore?.sitecoreContext()?.itemPath ?? '';

  const fullUrl = computed(() =>
    !isServer() ? window.location.href.split('?')[0] : venueOrigin + itemPath
  );

  const dataLayerDefaultProps = {
    [DEFAULT_DATA_LAYER]: {
      defaultGlobalProps: {
        event: 'basic_event',
        pub: venueName && venueId ? `${venueName}-${venueId}` : null,
        link_page_path: itemPath,
        link_domain: venueOrigin,
      },
    },
    [ECOMMERCE_DATA_LAYER_ITEM]: {
      defaultItemProps: {
        location_id: fullUrl.value,
      },
    },
    [VENUE_FINDER_DATA_LAYER]: {
      defaultGlobalProps: {
        event: 'basic_event',
        link_page_path: itemPath,
        link_domain: venueOrigin,
      },
    },
  };

  return dataLayerDefaultProps?.[dataLayerType] || {};
};
