import { isServer } from '@sitecore-jss/sitecore-jss';

const a11yTab = (event) => {
  if (event.key === 'Tab') document.body.classList.add('tabfocus');
};
const a11yCursor = () => {
  document.body.classList.remove('tabfocus');
};

export const enableA11yFocus = () => {
  if (isServer()) return;
  document.addEventListener('keydown', a11yTab);
  document.addEventListener('mousedown', a11yCursor);
  document.addEventListener('mouseup', a11yCursor);
};

export const disableA11yFocus = () => {
  if (isServer()) return;
  document.removeEventListener('keydown', a11yTab);
  document.removeEventListener('mousedown', a11yCursor);
  document.removeEventListener('mouseup', a11yCursor);
};

export default enableA11yFocus;
