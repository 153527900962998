<template>
  <article
    class="promo-list-item"
    :class="{
      'promo-list-item--editor': $jss.sitecoreContext().pageEditing,
      'promo-list-item--no-content': !hasContent,
    }"
  >
    <div v-if="fields?.imageDesktop?.value?.src" class="promo-list-item__image">
      <base-image
        class="promo-list-item__pic"
        :desktop-url="fields?.imageDesktop?.value?.src"
        :desktop-alt="fields?.imageDesktop?.value?.alt"
        :desktop-dimensions="[
          fields?.imageDesktop?.value?.width,
          fields?.imageDesktop?.value?.height,
        ]"
        :mobile-url="fields?.imageMobile?.value?.src"
        :mobile-alt="fields?.imageMobile?.value?.alt"
        :mobile-dimensions="[
          fields?.imageMobile?.value?.height,
          fields?.imageMobile?.value?.height,
        ]"
        :custom-params="
          !single
            ? { desktop: 500, tablet: 460, mobile: 445, mobileS: 250 }
            : { desktop: 500, tablet: 500, mobile: 500, mobileS: 400 }
        "
        fallback-param="s"
        lazy-loading="lazy"
      />
      <base-link
        v-if="fields?.link?.value?.href || $jss.sitecoreContext().pageEditing"
        :field="fields?.link"
        classes="promo-list-item__image-link"
        tabindex="-1"
        @click="registerVisiblePromoItem()"
      />
    </div>
    <div v-if="hasContent" class="promo-list-item__content">
      <h3
        v-if="fields?.link?.value?.href && !$jss.sitecoreContext().pageEditing"
        class="promo-list-item__heading"
        :class="brandClasses"
      >
        <base-link :field="titleLink" @click="registerVisiblePromoItem()" />
      </h3>
      <sc-text
        v-else
        tag="h3"
        class="promo-list-item__heading"
        :class="brandClasses"
        :field="fields?.title"
      />

      <sc-text tag="p" class="promo-list-item__text" :field="fields?.text" />
      <base-link
        v-if="fields?.link?.value?.href || $jss.sitecoreContext().pageEditing"
        :field="fields?.link"
        classes="promo-list-item__link link"
        @click="registerVisiblePromoItem()"
      />
    </div>
  </article>
</template>

<script setup>
import { inject, computed } from 'vue';
import { Text as ScText } from '@sitecore-jss/sitecore-jss-vue';
import BaseLink from '../../2_elements/BaseLink/BaseLink.vue';
import BaseImage from '../../2_elements/BaseImage/BaseImage.vue';
import { promoListDataLayer } from '../../../data-layer/promoList';
import { useRegisterEcommerceDataLayerAction } from '../../../data-layer/helpers/registerEcommerceDataLayer';

const props = defineProps({
  fields: {
    type: Object,
  },
  params: {
    type: Object,
  },
  single: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
  },
});

const jssStore = inject('jssStore');
const brandName = jssStore?.sitecoreContext()?.brandName;

const titleLink = computed(() => ({
  value: {
    href: props.fields?.link?.value?.href,
    linktype: props.fields?.link?.value?.linktype,
    text: props.fields?.title?.value,
  },
}));

const brandClasses = computed(() => ({
  'font-l': brandName === 'HungryHorse',
  'font-s': ['Belhaven', 'Everly', 'Seared', 'FarmhouseKitchen', 'Kinsmith', 'HighTales'].includes(
    brandName
  ),
}));

const registerEcommerceDataLayerAction = useRegisterEcommerceDataLayerAction();

const hasContent = computed(
  () =>
    !!(props.fields?.title?.value || props.fields?.text?.value || props.fields?.link?.value?.href)
);

const registerVisiblePromoItem = () => {
  registerEcommerceDataLayerAction({
    event: promoListDataLayer.categories.SELECT_PROMOTION,
    ecommerce: {
      items: [
        {
          promotion_id: props?.fields?.title?.value,
          promotion_name: props?.fields?.title?.value,
          creative_name: props?.fields?.text?.value,
          creative_slot: props?.index + 1,
        },
      ],
    },
  });
};
</script>

<script>
export default {
  name: 'DisablingAttributeInheritance',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<style lang="scss">
@import './scss/PromoListItem.scss';
</style>
