import { createRouter as createVueRouter, createWebHistory, createMemoryHistory } from 'vue-router';
import RouteHandler from './RouteHandler.vue';
import qs from 'query-string';

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute(.*)*',
  '/:lang([a-z]{2})/:sitecoreRoute(.*)*',
  '/:sitecoreRoute(.*)*',
];

export function createRouter(isSSR) {
  // create an instance of vue-router and configure routes to use the RouteHandler component
  const router = createVueRouter({
    history: isSSR ? createMemoryHistory() : createWebHistory(),
    routes: routePatterns.map((routePattern) => {
      return {
        path: routePattern,
        component: RouteHandler,
        props: (route) => ({
          route,
        }),
      };
    }),
    parseQuery: (query) => qs.parse(query),
    stringifyQuery: (query) => qs.stringify(query, { sort: false }).replaceAll('%20', '+'),
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        return {
          el: to.hash,
          behavior: 'smooth',
        };
      }

      if (to.path === from.path && (to.query.type || to.query.section)) {
        return;
      }

      if (savedPosition) {
        return savedPosition;
      }

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ left: 0, top: 0 });
        }, 100);
      });
    },
  });

  router.beforeEach(() => {
    if (typeof document !== 'undefined' && document?.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  });

  return router;
}
