<template>
  <template v-if="bookingOptions.length > 1">
    <div
      class="booking-menu"
      :class="[{ 'booking-menu--active': menuActive }]"
      data-booking-block
      @mouseover="mouseOver"
      @mouseleave="closeMenuWithTimeout"
    >
      <button
        class="btn btn--secondary booking-menu__dropdownButton"
        :aria-expanded="menuActive"
        :tabindex="ctaButtonTabIndex"
        @click="handleKeyDown"
        @keydown.enter.space.prevent="handleKeyDown"
        @keydown.tab.shift.exact="closeMenu"
      >
        {{ t('book-cta-text-short') }}
        <i class="icon icon-Down" aria-hidden="true"></i>
      </button>
      <div class="booking-menu__subMenu">
        <base-link
          v-for="option in bookingOptions"
          :key="option.key"
          :text="t(option.labelKey)"
          :classes="`booking-menu__item booking-menu__item--${option.key} ${option.icon} no-underline`"
          :field="{ value: option.data }"
          @click="onSubmenuItemClick(option)"
        />
      </div>
    </div>
  </template>
  <template v-else>
    <base-button
      v-for="option in bookingOptions"
      :key="option.key"
      class="main-header__button"
      data-booking-block
      variant="secondary"
      type="link"
      :tabindex="ctaButtonTabIndex"
      :link="option.data"
      :label="isDesktop ? t('book-cta-text-long') : t('book-cta-text-short')"
      :aria-label="isDesktop ? t('book-cta-text-long') : t('book-cta-text-short')"
      :data-cta-text-short="t('book-cta-text-short')"
      :data-cta-text-long="t('book-cta-text-long')"
      :data-test-id="`${option.key}-single-button`"
      @click="handleBookingClick(isDesktop ? t('book-cta-text-long') : t('book-cta-text-short'))"
    />
  </template>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import BaseButton from '../../2_elements/BaseButton/BaseButton.vue';
import BaseLink from '../../2_elements/BaseLink/BaseLink.vue';
import useMedia from '../../../utils/useMedia';

const { t } = useI18n();
const { isMedia: isDesktop } = useMedia('(min-width: 1024px)');
const menuActive = ref(false);
let openingTimeout = null;
let closingTimeout = null;

const emit = defineEmits(['booking-menu-open']);

const props = defineProps({
  bookingOptions: {
    type: Array,
    default: () => [],
  },
  handleBookingClick: {
    type: Function,
  },
  ctaButtonTabIndex: {
    type: Number,
  },
});

const mouseOver = () => {
  if (openingTimeout) return;
  openMenu();
};

const handleKeyDown = () => {
  if (openingTimeout) return;
  menuActive.value ? closeMenu() : openMenu();
};

const openMenu = () => {
  if (closingTimeout) clearTimeout(closingTimeout);
  menuActive.value = true;
  setOpeningTimeout();
  emit('booking-menu-open');
};

const closeMenu = () => {
  menuActive.value = false;
};

const setOpeningTimeout = () => {
  if (openingTimeout) resetOpeningTimeout();
  openingTimeout = setTimeout(resetOpeningTimeout, 50);
};

const resetOpeningTimeout = () => {
  clearTimeout(openingTimeout);
  openingTimeout = null;
};

const closeMenuWithTimeout = () => {
  closingTimeout = setTimeout(closeMenu, 300);
};

const onSubmenuItemClick = (option) => {
  props.handleBookingClick(t(option.labelKey));
  closeMenu();
};

defineExpose({ closeMenu });
</script>

<style lang="scss">
@import './scss/BookingMenu.scss';
</style>
