<template>
  <section class="sub-navigation" :class="mainClasses">
    <nav class="sub-navigation__container">
      <base-nav-list
        :items="fields?.firstLevel?.value"
        data-test-id="sub-nav-1-lvl"
        :handle-sub-navigation-click="handleSubNavigationClick"
      />
      <base-nav-list
        v-if="fields?.secondLevel?.value?.length"
        :items="fields?.secondLevel?.value"
        second-level
        data-test-id="sub-nav-2-lvl"
        :handle-sub-navigation-click="handleSubNavigationClick"
      />
    </nav>
  </section>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useRegisterDataLayerAction } from '../../data-layer/helpers/registerDataLayer';
import BaseNavList from '../2_elements/BaseNavList/BaseNavList.vue';

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const jssStore = inject('jssStore');
const brandName = jssStore?.sitecoreContext()?.brandName || null;
const registerDataLayerAction = useRegisterDataLayerAction();

const mainClasses = computed(() => [
  brandName === 'ChefBrewer' && 'bottom-white-border',
  brandName === 'Belhaven' && 'blh-full-width',
  !props.fields?.secondLevel?.value?.length && 'sub-navigation--one-lvl',
]);

const handleSubNavigationClick = ({ title }) => {
  registerDataLayerAction({
    event_name: 'click_link',
    link_text: title,
    intent: 'navigation_interaction',
    component: 'sub_navigation',
  });
};
</script>

<style lang="scss">
@import './scss/SubNavigation.scss';
</style>
