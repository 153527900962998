<template>
  <section class="introduction-admission show-on-load">
    <introduction-item
      :fields="fields"
      :rendering="rendering"
      :params="params"
      :opening-hours-description="fields?.openingHoursDescription?.value"
      :admission="true"
    >
      <template #admissionTypes>
        <div class="introduction-admission__admission-types opening-hours-item">
          <button class="opening-hours-item__header" :aria-expanded="isExpanded" @click="onClick">
            <i class="icon icon-Season-Ticket" aria-hidden="true"></i>
            <h3 class="opening-hours-item__title">{{ t('admission-types') }}</h3>
            <i class="opening-hours-item__header-state-icon icon icon-Up" aria-hidden="true"></i>
          </button>
          <div class="introduction-admission__items">
            <span>{{ fields?.admissionType1Label?.value }}</span>
            <div>
              <span class="introduction-admission__items-values">
                {{ fields?.admissionType1Value?.value }}
              </span>
            </div>
          </div>
          <div
            class="introduction-admission__collapse-wrapper"
            :class="!isExpanded && 'introduction-admission__collapse-wrapper--hidden'"
          >
            <div class="introduction-admission__items">
              <span>{{ fields?.admissionType2Label?.value }}</span>
              <div>
                <span class="introduction-admission__items-values">
                  {{ fields?.admissionType2Value?.value }}
                </span>
              </div>
            </div>
            <p
              v-if="fields?.admissionTypesDescription?.value"
              class="introduction-admission__description font-s-copy"
            >
              {{ fields?.admissionTypesDescription?.value }}
            </p>
          </div>
        </div>
      </template>
    </introduction-item>
  </section>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import IntroductionItem from '../IntroductionItem/IntroductionItem.vue';

const { t } = useI18n();

defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const isExpanded = ref(false);

const onClick = () => {
  isExpanded.value = !isExpanded.value;
};
</script>

<style lang="scss">
@import './scss/IntroductionAdmission.scss';
</style>
