<template>
  <section
    v-if="isEditorActive() || heroSlides.length"
    class="hero-carousel show-on-load"
    :class="mainClasses"
  >
    <div v-if="!isServer() && !$jss.sitecoreContext().pageEditing" class="hero-carousel__container">
      <swiper
        v-if="contentCardsEnabled || heroCarouselItemsLength > 1"
        v-bind="swiperOptions"
        class="hero-carousel__swiper"
        @swiper="onSwiper"
        @mouseenter="onFocus"
        @mouseleave="startAutoplayIfRun"
        @keyup.tab="onFocus"
        @slide-change-transition-end="onSlideChange"
        @autoplay="onAutoplaySlideChange"
      >
        <template v-for="(slide, index) in heroSlides">
          <swiper-slide
            v-if="index < slideLimit"
            :key="slide?.uid ?? slide?.fields?.contentCardId"
            v-slot="{ isActive, isVisible }"
            tabindex="-1"
          >
            <hero-carousel-item
              v-bind="slide"
              :index="index"
              :is-carousel="true"
              :is-content-card="index === contentCardIndex"
              :heading-type="params.headingType ?? 'h1'"
              :aria-hidden="!(isActive && isVisible)"
            />
          </swiper-slide>
        </template>

        <div v-show="heroCarouselItemsLength > 1" class="container">
          <div class="hero-carousel__controls var-dark">
            <div class="hero-carousel__pagination"></div>
            <button
              :class="[
                'hero-carousel__autoplay-btn',
                pauseBtnVisible
                  ? 'hero-carousel__autoplay-btn--pause'
                  : 'hero-carousel__autoplay-btn--play',
              ]"
              :aria-label="pauseBtnVisible ? t('slider-pause') : t('slider-play')"
              @click="toggleAutoplay"
              @keydown.tab="startAutoplayIfRun"
            >
              <i
                :class="['icon', pauseBtnVisible ? 'icon-Pause' : 'icon-Play']"
                aria-hidden="true"
              ></i>
            </button>
          </div>
        </div>
      </swiper>
      <template v-else-if="!contentCardsEnabled && heroCarouselItemsLength === 1">
        <hero-carousel-item v-bind="heroSlides[0]" :heading-type="params.headingType ?? 'h1'" />
      </template>
    </div>
    <div v-else-if="$jss.sitecoreContext().pageEditing" class="hero-carousel__container--editor">
      <sc-placeholder name="hero-carousel-items" :rendering="rendering" />
      <h2 v-if="heroCarouselItemsLength > slideLimit" class="error">
        Warning! Only the first {{ slideLimit }} items will be rendered!
      </h2>
    </div>
    <div v-else class="hero-carousel__container">
      <sc-placeholder name="hero-carousel-items" :rendering="rendering" />
    </div>
  </section>
</template>

<script setup>
import { ref, computed, onMounted, inject, watch } from 'vue';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { Placeholder as ScPlaceholder, isEditorActive } from '@sitecore-jss/sitecore-jss-vue';
import { useI18n } from 'vue-i18n';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, EffectFade, Navigation, Pagination, A11y } from 'swiper/modules';
import HeroCarouselItem from './HeroCarouselItem/HeroCarouselItem.vue';
import { useContentCardsStore } from '../../stores/contentCardsStore';

import 'swiper/scss';
import 'swiper/scss/effect-fade';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/css/a11y';

const { t } = useI18n();
const { getHeroCarouselContentCards } = useContentCardsStore();

const jssStore = inject('jssStore');
const brandName = jssStore?.sitecoreContext()?.brandName;
const contentCardsEnabled = jssStore?.sitecoreContext()?.enableContentCard;

// true when slide is changed by autoplay
let autoplaySlideChange = false;

const slideLimit = 5;
const swiperCarousel = ref(null);

const props = defineProps({
  rendering: {
    type: Object,
  },
  fields: {
    type: Object,
  },
  params: {
    type: Object,
    default: () => ({}),
  },
});

const autoplayIsRun = ref(false);
const initialDelay = ref(true);

const swiperOptions = {
  effect: 'fade',
  pagination: {
    el: '.hero-carousel__pagination',
    bulletElement: 'button',
    clickable: true,
  },
  a11y: {
    paginationBulletMessage: `${t('slider-pagination')} {{index}}`,
    slideLabelMessage: '{{index}} of {{slidesLength}}',
  },
  modules: [Autoplay, EffectFade, Navigation, Pagination, A11y],
  breakpoints: {
    0: {
      allowTouchMove: true,
    },
    1024: {
      allowTouchMove: false,
    },
  },
  autoplay: {
    delay: 7000,
    disableOnInteraction: false,
  },
};

const heroCarouselContentCards = computed(() => getHeroCarouselContentCards());

const isContentCardAvailable = computed(() => heroCarouselContentCards.value?.length);

const getInitialSlides = () => {
  if (contentCardsEnabled && isContentCardAvailable.value)
    return [heroCarouselContentCards.value[0]];
  return props?.rendering?.placeholders['hero-carousel-items'].filter((s) => s.fields) ?? [];
};

const heroSlides = ref(getInitialSlides());

const heroCarouselItemsLength = computed(
  () => heroSlides.value.filter((item) => item.componentName === 'HeroCarouselItem')?.length
);

const contentCardIndex = computed(() => heroSlides.value.findIndex((slide) => slide?.contentCard));

const pauseBtnVisible = computed(() => autoplayIsRun.value || initialDelay.value);

const mainClasses = computed(() => [brandName === 'Belhaven' && 'blh-full-width']);

const replaceSlideWithContentCard = () => {
  if (isContentCardAvailable.value) {
    heroSlides.value.splice(
      swiperCarousel.value?.activeIndex === 0 ? 1 : 0,
      1,
      heroCarouselContentCards.value[0]
    );

    setTimeout(() => {
      initialDelay.value = false;
      swiperCarousel.value?.slideTo(contentCardIndex.value);
      stopAutoplay();
    }, 0);
  }
};

const onSwiper = (swiper) => {
  swiperCarousel.value = swiper;
};

const toggleAutoplay = () => {
  autoplayIsRun.value || initialDelay.value ? stopAutoplay() : startAutoplay();
  initialDelay.value = false;
};

const stopAutoplay = () => {
  swiperCarousel.value?.autoplay?.stop();
  autoplayIsRun.value = false;
};

const startAutoplay = () => {
  swiperCarousel.value?.autoplay?.start();
  autoplayIsRun.value = true;
};

const startAutoplayIfRun = () => {
  if (autoplayIsRun.value) {
    swiperCarousel.value?.autoplay?.start();
  }
};

const onFocus = () => {
  swiperCarousel.value?.autoplay?.stop();
};

const onSlideChange = (swiper) => {
  if (autoplaySlideChange) autoplaySlideChange = false;
  else {
    swiper.slides[swiper.activeIndex]?.focus({
      preventScroll: true,
    });
  }
};

const onAutoplaySlideChange = () => {
  autoplaySlideChange = true;
};

watch(
  isContentCardAvailable,
  () => {
    if (contentCardsEnabled && contentCardIndex.value < 0) replaceSlideWithContentCard();
  },
  { immediate: true }
);

onMounted(() => {
  stopAutoplay();
  setTimeout(() => {
    initialDelay.value && startAutoplay();
  }, 3000);
});
</script>

<style lang="scss">
@import './scss/HeroCarousel.scss';
</style>
