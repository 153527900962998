<template>
  <section
    class="pub-info-bar show-on-load"
    :class="{
      ...detectComponentVariant(variantedComponents.PUB_INFO_BAR),
    }"
    @keydown.esc="onEscKeydown"
  >
    <div class="pub-info-bar__container container">
      <div class="pub-info-bar__text-container">
        <router-link class="pub-info-bar__pub-info" :to="fields.homepageLink?.value?.href">
          {{ fields.pubInfo?.value }}
        </router-link>
        <div class="pub-info-bar__opening-hours">
          {{ openingHours }}
        </div>
      </div>
      <button
        v-if="fields.isSpecialHours?.value"
        class="pub-info-bar__occasion-info-toggle"
        :class="{ open: isOpen }"
        :aria-label="'Toggle ' + t('pub-info-bar-tooltip-link-label')"
        :aria-expanded="isOpen"
        @click="toggleOccasionInfo"
      >
        <i class="icon icon-Info" aria-hidden="true"></i>
      </button>

      <div
        v-if="isOpen"
        v-on-click-outside="toggleOccasionInfo"
        class="pub-info-bar__occasion-info-content"
        tabindex="-1"
      >
        <template v-if="fields.occasionName?.value">
          {{ fields.occasionName?.value }} {{ t('pub-info-bar-tooltip') }}
        </template>
        <template v-else>
          {{ t('pub-info-bar-tooltip-no-occasion-name') }}
        </template>

        <router-link :to="fields.toolTipLink?.value?.href">
          {{ t('pub-info-bar-tooltip-link-label') }}
        </router-link>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDebounceFn } from '@vueuse/core';
import { vOnClickOutside } from '@vueuse/components';
import { useDetectVariantByBrand, variantedComponents } from '../../utils/componentVariants';

const detectComponentVariant = useDetectVariantByBrand();

const { t } = useI18n();

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const isOpen = ref(false);

const toggleOccasionInfo = useDebounceFn(() => {
  isOpen.value = !isOpen.value;
}, 10);

const onEscKeydown = () => {
  if (isOpen.value) toggleOccasionInfo();
};

const openingHours = computed(() =>
  props.fields.openingHours?.value
    ? `${t('pub-info-bar-open')} ${props.fields.openingHours.value}`
    : t('pub-info-bar-closed')
);
</script>

<style lang="scss">
@import './scss/PubInfoBar.scss';
</style>
