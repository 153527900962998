<template>
  <component
    v-bind="binds"
    :is="buttonType === 'iframe-link' ? IframeLink : buttonType"
    ref="btn"
    :class="classes"
    @click="onClick"
  >
    <template v-if="!isLoading">
      {{ text }}
      <i v-if="icon" :class="{ ['icon']: true, [`${icon}`]: !!icon }" aria-hidden="true"></i>
    </template>
    <div v-else class="btn__loading" :aria-label="loadingLabel">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </div>
    <span
      v-if="circle.isVisible"
      class="btn__circle"
      :style="{ left: `${circle.posX}px`, top: `${circle.posY}px` }"
      aria-hidden="true"
      @animationend="resetAnimation"
    ></span>
  </component>
</template>

<script setup>
import { computed, ref, reactive, onMounted } from 'vue';
import { getLinkTag } from '../../../utils/linkTransformer';
import IframeLink from '../../2_elements/IframeLink/IframeLink.vue';

const ANCHOR = 'anchor';

const props = defineProps({
  label: String,
  type: {
    type: String,
    default: 'button',
  },
  variant: {
    type: String,
    default: 'primary',
    validator: (value) => {
      return ['primary', 'secondary'].includes(value);
    },
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  loadingLabel: {
    type: String,
    default: 'Loading...',
  },
  link: {
    type: Object,
    default: null,
  },
  icon: String,
  rounded: {
    type: Boolean,
    default: false,
  },
  limit: {
    type: Number,
    default: 160,
  },
  tabindex: {
    type: Number,
    default: null,
  },
});

const isMounted = ref(false);

const buttonType = computed(() => {
  if (props.type === 'link') {
    // return router-link for internal links and just a for external
    return getLinkTag(props.link?.linktype);
  } else return 'button';
});

const classes = computed(() => ({
  ['btn']: true,
  ['no-transition']: !isMounted.value,
  [`btn--${props.variant}`]: !!props.variant,
  ['btn--loading']: props.isLoading,
  ['btn--icon']: !!props.icon && !props.isLoading,
  ['btn--rounded']: props.rounded,
  ['btn--only-icon']: !!props.icon && !props.label && !props.link?.text,
}));

const text = computed(() => {
  const txt = props.label || props.link?.text;
  if (!txt?.length || !props.limit) return txt;
  return txt.length > props.limit ? txt.substring(0, props.limit) + '...' : txt;
});

const href = computed(() => {
  let url = props.link?.href;
  if (url && props.link.anchor && props.link.linktype !== ANCHOR) url += `#${props.link.anchor}`;
  return url;
});

const binds = computed(() => {
  const obj = {};

  if (props.type !== 'link') obj.type = props.type;
  if (buttonType.value === 'router-link' && href.value) obj.to = href.value;
  if (buttonType.value === 'a' && href.value) obj.href = href.value;
  if (props.link?.target) obj.target = props.link.target;
  if (props.link?.target === '_blank') obj.rel = 'noopener noreferrer';
  if (props.link?.alt) obj.alt = props.link.alt;
  if (props.disabled) obj.disabled = props.disabled;
  if (buttonType.value === 'iframe-link') {
    obj.baseButton = true;
    if (props.label) obj.text = props.label;
    if (href.value) obj.href = href.value;
  }
  obj.tabindex = props.tabindex;

  return obj;
});

const circle = reactive({
  isVisible: false,
  posX: null,
  posY: null,
});

const btn = ref();

const resetAnimation = () => {
  circle.isVisible = false;
  circle.posX = null;
  circle.posY = null;
};

const emit = defineEmits(['click']);

const onClick = (e) => {
  if (props.isLoading) return;

  emit('click');
  resetAnimation();

  const dims = btn.value.getBoundingClientRect
    ? btn.value.getBoundingClientRect()
    : btn.value.$el.getBoundingClientRect();

  const btnArea = {
    minX: dims.x,
    maxX: dims.width,
    minY: dims.y,
    maxY: dims.height,
  };

  const mousePos = {
    x: e.clientX,
    y: e.clientY,
  };

  const x = mousePos.x - btnArea.minX;
  const y = mousePos.y - btnArea.minY;

  if (x >= 0 && x <= btnArea.maxX && y >= 0 && y <= btnArea.maxY) {
    circle.posX = x;
    circle.posY = y;
  } else {
    circle.posX = btnArea.maxX / 2;
    circle.posY = btnArea.maxY / 2;
  }

  circle.isVisible = true;
};

onMounted(() => {
  isMounted.value = true;
});
</script>

<style lang="scss">
@import './scss/BaseButton.scss';
</style>
