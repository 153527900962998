<template>
  <section class="introduction-image" :class="mainClasses">
    <div class="container">
      <div class="row">
        <div class="introduction-image__texts-wrapper col-12 col-lg-7">
          <div class="introduction-image__texts">
            <sc-text
              class="introduction-image__header"
              :class="headerClasses"
              tag="h2"
              :field="fields?.title"
            />
            <p class="introduction-image__description">
              <span>
                {{ introductionItemDescription }}
              </span>
              <button
                v-if="showReadMore"
                :aria-expanded="isShowMoreOpened"
                class="link introduction-image__description-more"
                @click="handleClickShowMore"
              >
                {{ isShowMoreOpened ? t('introduction-show-less') : t('introduction-show-more') }}
              </button>
            </p>
            <div v-if="cta1Text || cta2Text" class="introduction-image__buttons">
              <base-button
                v-if="cta1Text"
                class="introduction-image__button"
                data-test-id="introduction-cta1"
                :label="cta1Text"
                type="link"
                :link="fields?.cta1.value"
                variant="primary"
                @click="handleActionClick(cta1Text)"
              />
              <base-button
                v-if="cta2Text"
                class="introduction-image__button"
                data-test-id="introduction-cta2"
                :label="cta2Text"
                type="link"
                :link="fields?.cta2.value"
                variant="secondary"
                @click="handleActionClick(cta2Text)"
              />
            </div>
            <div
              v-if="openingHoursVisible"
              class="introduction-image__opening-hours"
              :class="{ ...detectComponentVariant(variantedComponents.OPENING_HOURS) }"
            >
              <opening-hours
                v-if="fields?.openingHours?.value?.length"
                :title="dictionary.openingHours"
                :icon="'Clock'"
                :opening-hours="fields.openingHours"
                :first-bolded="!fields.enableSpecialHoursAdvancedNotice?.value"
              />
              <opening-hours
                v-if="fields?.servingHours?.value?.length"
                :title="dictionary.servingHours"
                :icon="'Cutlry'"
                :opening-hours="fields.servingHours"
                :first-bolded="!fields.enableSpecialHoursAdvancedNotice?.value"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-5">
          <div v-if="isImage" class="introduction-image__image">
            <div class="introduction-image__image-wrapper var-dark">
              <base-image
                :desktop-url="fields.image.value.src"
                :desktop-alt="fields.image.value.alt"
                :custom-params="{ mobileS: 400, mobile: 720, tablet: 500, desktop: 840 }"
                fallback-param="s"
              />
              <base-button
                v-if="galleryLinkVisible"
                :link="{ href: '#gallery' }"
                type="link"
                variant="primary"
                :label="t('introduction-view-gallery')"
                class="introduction-image__image-button"
              />
            </div>
            <a
              v-if="galleryLinkVisible"
              href="#gallery"
              class="introduction-image__image-link link"
            >
              {{ t('introduction-view-gallery') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, ref, inject, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { Text as ScText } from '@sitecore-jss/sitecore-jss-vue';
import BaseButton from '../2_elements/BaseButton/BaseButton.vue';
import BaseImage from '../2_elements/BaseImage/BaseImage.vue';
import OpeningHours from '../2_elements/OpeningHoursItem/OpeningHoursItem.vue';
import { useRegisterDataLayerAction } from '../../data-layer/helpers/registerDataLayer';
import { contentButtonsDataLayer } from '../../data-layer/contentButtons';
import { useDetectVariantByBrand, variantedComponents } from '../../utils/componentVariants';

const detectComponentVariant = useDetectVariantByBrand();
const { t } = useI18n();

const jssStore = inject('jssStore');
const brandName = jssStore?.sitecoreContext()?.brandName;

const MIN_COUNT_OF_GALLERY_SLIDES = 2;

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const mainClasses = computed(() => ({
  'bottom-paper-border': brandName === 'ChefBrewer',
  'introduction-image--only-texts': !openingHoursVisible.value && !isImage.value,
  ...detectComponentVariant(variantedComponents.INTRODUCTION_IMAGE),
}));

const headerClasses = computed(() => [
  ['HungryHorse', 'Belhaven'].includes(brandName) ? 'font-xl' : 'font-l',
]);

const isImage = computed(() => !!props.fields?.image?.value?.src);

const cta1Text = computed(() => props.fields?.cta1?.value?.text);
const cta2Text = computed(() => props.fields?.cta2?.value?.text);

const dictionary = computed(() => {
  const specialHrs = props.fields.enableSpecialHoursAdvancedNotice?.value;
  return {
    openingHours: t(
      specialHrs ? 'special-hours-advanced-notice-festive-opening-hours' : 'opening-hours'
    ),
    servingHours: t(specialHrs ? 'special-hours-advanced-notice-kitchen-hours' : 'serving-hours'),
  };
});

const DESCRIPTION_MAX_VISIBLE_LENGTH = 260;
const showReadMore = ref(props.fields?.description?.value?.length > DESCRIPTION_MAX_VISIBLE_LENGTH);
const isShowMoreOpened = ref(false);
const galleryLinkVisible = ref(false);

const introductionItemDescription = computed(() =>
  !isShowMoreOpened.value && showReadMore.value
    ? `${props.fields?.description?.value?.slice(0, DESCRIPTION_MAX_VISIBLE_LENGTH)}...`
    : props.fields?.description?.value
);

const handleClickShowMore = () => {
  handleActionClick(
    !isShowMoreOpened.value
      ? contentButtonsDataLayer.actions.SHOW_MORE
      : contentButtonsDataLayer.actions.SHOW_LESS
  );
  isShowMoreOpened.value = !isShowMoreOpened.value;
};

const openingHoursVisible = computed(
  () => props.fields?.openingHours?.value.length || props.fields?.servingHours?.value.length
);

const registerDataLayerAction = useRegisterDataLayerAction();

const handleActionClick = (action) => {
  registerDataLayerAction({
    event_name: 'click_button',
    link_text: action,
    intent: action,
    component: 'introduction_image',
  });
};

onMounted(() => {
  galleryLinkVisible.value =
    jssStore
      ?.routeData()
      ?.placeholders?.['jss-main']?.find((component) => component?.componentName === 'GalleryBase')
      ?.fields?.slides?.value?.length >= MIN_COUNT_OF_GALLERY_SLIDES;
});
</script>

<style lang="scss">
@import './scss/IntroductionImage.scss';
</style>
