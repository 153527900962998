import { isServer } from '@sitecore-jss/sitecore-jss';

import { DEFAULT_DATA_LAYER, useDataLayerDefaultProps } from './defaultProps';
import { fillEmptyValues } from './utils';

export const useRegisterDataLayerAction = (dataLayerType = DEFAULT_DATA_LAYER) => {
  const defaultGlobalProps = useDataLayerDefaultProps(dataLayerType);

  return (data) => {
    if (typeof window !== 'undefined' && !isServer()) {
      window.dataLayer = window?.dataLayer || [];

      const normalizedData = fillEmptyValues({
        ...defaultGlobalProps?.defaultGlobalProps,
        ...data,
      });

      window.dataLayer.push(normalizedData);

      return window.dataLayer;
    }
  };
};
