import { computed, toValue } from 'vue';

export function useSources({
  componentName,
  desktopUrl,
  tabletUrl,
  mobileUrl,
  fallbackParam,
  customParams,
  skipLargestBreakpoint = false,
}) {
  const BREAKPOINTS = {
    MOBILE_S: { name: 'mobileS', defaultDimension: 430 },
    MOBILE: { name: 'mobile', defaultDimension: 768 },
    TABLET: { name: 'tablet', defaultDimension: 1024 },
    DESKTOP: { name: 'desktop', defaultDimension: 1540 },
    FHD_DESKTOP: { name: 'fullWidthDesktop', defaultDimension: 1920 },
  };

  const defaultDimensions = Object.values(BREAKPOINTS).map(
    ({ defaultDimension }) => defaultDimension
  );

  const customProps = () => {
    if (Object.keys(preloadedSourcesParams).includes(componentName))
      return {
        ...preloadedSourcesParams[componentName],
        skipLargestBreakpoint:
          preloadedSourcesParams[componentName]?.skipLargestBreakpoint || skipLargestBreakpoint,
      };

    return {
      fallbackParam,
      customParams,
      skipLargestBreakpoint,
    };
  };

  const addUrlParams = (url, breakpoint) => {
    const regex = /[?]/;
    const isAnyParam = !!regex.exec(url);
    const param = customProps()?.customParams?.[breakpoint.name] ?? breakpoint.defaultDimension;

    return url + (isAnyParam ? '&' : '?') + `t=${getFallbackParam(breakpoint.name)}${param}`;
  };

  const getFallbackParam = (breakpointName) => {
    if (typeof customProps()?.fallbackParam === 'object') {
      return customProps()?.fallbackParam?.[breakpointName] || 'w';
    }
    return customProps()?.fallbackParam || 'w';
  };

  const getSources = computed(() => [
    addUrlParams(toValue(mobileUrl) ?? toValue(desktopUrl), BREAKPOINTS.MOBILE_S),
    addUrlParams(toValue(mobileUrl) ?? toValue(desktopUrl), BREAKPOINTS.MOBILE),
    addUrlParams(toValue(tabletUrl) ?? toValue(desktopUrl), BREAKPOINTS.TABLET),
    addUrlParams(toValue(desktopUrl), BREAKPOINTS.DESKTOP),
    ...(!customProps().skipLargestBreakpoint
      ? [addUrlParams(toValue(desktopUrl), BREAKPOINTS.FHD_DESKTOP)]
      : []),
  ]);

  return { getSources, defaultDimensions };
}

const preloadedSourcesParams = {
  'MultipurposeImageBanner--split': {
    customParams: {
      mobileS: 445,
      mobile: 768,
      tablet: 'Tablet',
      desktop: 840,
      fullWidthDesktop: 960,
    },
    fallbackParam: { mobileS: 's', mobile: 's', tablet: 'mibSplit' },
  },
  'MultipurposeImageBanner--full': {
    customParams: { mobileS: 'MobileS', mobile: 768 },
    fallbackParam: { mobileS: 'hero', mobile: 's' },
  },
  HeroCarouselItem: {
    fallbackParam: 'hero',
    customParams: {
      mobileS: 'MobileS',
      mobile: 'Mobile',
      tablet: 'Tablet',
      desktop: 'Desktop',
      fullWidthDesktop: 'FullHD',
    },
  },
  GalleryBase: {
    fallbackParam: {
      mobileS: 'gallery',
      mobile: 's',
    },
    customParams: {
      mobileS: 'MobileS',
      mobile: 715,
    },
    skipLargestBreakpoint: true,
  },
  SportHeroBannerItem: {
    fallbackParam: 'sportHero',
    customParams: {
      mobileS: 'MobileS',
      mobile: 'Mobile',
      tablet: 'Tablet',
      desktop: 'Desktop',
      fullWidthDesktop: 'FullHD',
    },
  },
};
