<template>
  <div class="loading-placeholder" :class="modifierClass">
    <div class="loading-placeholder__sub-navigation"></div>
    <div class="container">
      <div class="loading-placeholder__content">
        <section class="loading-placeholder__heading"></section>
        <div class="loading-placeholder__list">
          <section
            v-for="index in itemsNumber"
            :key="`placeholder-card-${index}`"
            class="loading-placeholder__card"
          ></section>
        </div>
        <loading-spinner />
      </div>
    </div>
  </div>
</template>

<script setup>
import LoadingSpinner from '../../../components/2_elements/LoadingSpinner/LoadingSpinner.vue';

const props = defineProps({
  itemsNumber: {
    type: Number,
  },
  modifierName: {
    type: String,
  },
});

const modifierClass = props.modifierName ? `loading-placeholder--${props.modifierName}` : null;
</script>

<style lang="scss">
@import './scss/LoadingPlaceholder.scss';
</style>
