export const normalizeDataLayerValue = (value) => {
  return typeof value !== 'string' ? value : value.toLowerCase().replaceAll(' ', '_');
};

export const fillEmptyValues = (data) => {
  const normalizedData = {};

  for (const key in data) {
    const preventNormalizedKey = key.startsWith('_');
    const normalizedKey = preventNormalizedKey ? key.substring(1, key.length) : key;

    const normalizedValue = preventNormalizedKey ? data[key] : normalizeDataLayerValue(data[key]);
    normalizedData[normalizedKey] = !data[key] ? `${normalizedKey}_not_defined` : normalizedValue;
  }

  return normalizedData;
};
