<template>
  <section class="zone-wrapper">
    <div class="zone-wrapper__wrapper container">
      <slot></slot>
    </div>
  </section>
</template>

<script setup>
import { onMounted } from 'vue';
import { useHead } from '@unhead/vue';

const props = defineProps({
  apiSettings: {
    type: Object,
    default: () => ({}),
  },
  scriptUrl: {
    type: String,
    required: true,
  },
  windowKey: {
    type: String,
    required: true,
  },
});

const configureBookTableScript = () => {
  window.__initialState = window.__initialState || {};
  window.__initialState[props.windowKey] = props.apiSettings;
};

useHead({
  script: [
    {
      src: `https://payments.worldpay.com/resources/hpp/integrations/embedded/js/hpp-embedded-integration-library.js`,
      defer: true,
    },
    {
      src: props.scriptUrl,
      defer: true,
    },
  ],
});

onMounted(() => {
  configureBookTableScript();
});
</script>

<style lang="scss">
@import './scss/ZoneWrapper.scss';
</style>
