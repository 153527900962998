<template>
  <picture>
    <source
      v-if="fullWidth"
      :srcset="getSources[4]"
      media="(min-width: 1540px)"
      :width="desktopDimensions[0] || defaultDimensions[4]"
      :height="desktopDimensions[1] || defaultDimensions[4]"
    />
    <source
      :srcset="getSources[3]"
      media="(min-width: 1024px)"
      :width="tabletDimensions[0] || desktopDimensions[0] || defaultDimensions[3]"
      :height="tabletDimensions[1] || desktopDimensions[1] || defaultDimensions[3]"
    />
    <source
      :srcset="getSources[2]"
      media="(min-width: 768px)"
      :width="tabletDimensions[0] || desktopDimensions[0] || defaultDimensions[2]"
      :height="tabletDimensions[1] || desktopDimensions[1] || defaultDimensions[2]"
    />
    <source
      :srcset="getSources[1]"
      media="(min-width: 431px)"
      :width="mobileDimensions[0] || desktopDimensions[0] || defaultDimensions[1]"
      :height="mobileDimensions[1] || desktopDimensions[1] || defaultDimensions[1]"
    />
    <img
      :src="getSources[0]"
      :alt="altText"
      draggable="false"
      :loading="lazyLoading"
      :width="mobileDimensions[0] || desktopDimensions[0] || defaultDimensions[0]"
      :height="mobileDimensions[1] || desktopDimensions[1] || defaultDimensions[0]"
      :fetchpriority="fetchpriority ?? null"
      @error="onError"
    />
  </picture>
</template>

<script setup>
import { computed, toRef } from 'vue';
import useMedia from '../../../utils/useMedia';

const { isMedia: isTablet } = useMedia('(min-width: 768px)');
const { isMedia: isDesktop } = useMedia('(min-width: 1024px)');

import { useSources } from '../../../composables/useSources';

const props = defineProps({
  mobileUrl: {
    type: String,
    required: false,
  },
  mobileDimensions: {
    type: Array,
    default: () => [],
  },
  tabletUrl: {
    type: String,
    required: false,
  },
  tabletDimensions: {
    type: Array,
    default: () => [],
  },
  desktopUrl: {
    type: String,
    required: true,
  },
  desktopDimensions: {
    type: Array,
    default: () => [],
  },
  mobileAlt: {
    type: String,
    required: false,
  },
  tabletAlt: {
    type: String,
    required: false,
  },
  desktopAlt: {
    type: String,
    required: true,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  lazyLoading: {
    type: String,
    default: () => undefined,
  },
  fallbackParam: {
    type: [String, Object],
    default: () => 'w',
  },
  customParams: {
    type: Object,
    default: () => ({
      mobileS: null,
      mobile: null,
      tablet: null,
      desktop: null,
      fullWidthDesktop: null,
    }),
  },
  fetchpriority: {
    type: String,
  },
  preloadImageName: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['error']);
const onError = (e) => emit('error', e);

const { getSources, defaultDimensions } = useSources({
  componentName: props.preloadImageName,
  desktopUrl: toRef(() => props.desktopUrl),
  tabletUrl: toRef(() => props.tabletUrl),
  mobileUrl: toRef(() => props.mobileUrl),
  customParams: props.customParams,
  fallbackParam: props.fallbackParam,
  skipLargestBreakpoint: !props.fullWidth,
});

const altText = computed(() => {
  if (isDesktop.value) return props.desktopAlt;
  if (isTablet.value) return props.tabletAlt ?? props.desktopAlt;
  return props.mobileAlt ?? props.desktopAlt;
});
</script>
