<template>
  <nav aria-label="breadcrumbs" class="breadcrumbs-bar show-on-load">
    <ol class="breadcrumbs-bar__container container">
      <li
        v-for="(link, index) in fields.links"
        :key="link?.href"
        class="breadcrumbs-bar__breadcrumb"
      >
        <component
          :is="isLastLink(index) ? 'span' : 'router-link'"
          :to="isLastLink(index) ? null : link.href"
          class="breadcrumbs-bar__breadcrumb-el"
          :class="isLastLink(index) ? 'breadcrumbs-bar__breadcrumb-el--text' : 'inverted-underline'"
        >
          {{ link?.title }}
        </component>
        <i
          v-if="!isLastLink(index)"
          class="icon icon-Forward breadcrumbs-bar__breadcrumb-icon"
          aria-hidden="true"
        />
      </li>
    </ol>
  </nav>
</template>

<script setup>
const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const isLastLink = (index) => index === props.fields?.links?.length - 1;
</script>

<style lang="scss">
@import './scss/BreadcrumbsBar.scss';
</style>
