export const mainHeaderBookingDataLayer = {
  category: 'booking_form',
  actions: {
    BOOK_A_TABLE: 'book_a_table',
    BOOK_A_ROOM: 'book_a_room',
  },
};

export const mainHeaderNavigationDataLayer = {
  category: 'navigation_click',
};
