<template>
  <base-button
    v-if="showCTAButton"
    class="main-header__button"
    :variant="brandName !== 'PubPartners' ? 'secondary' : 'primary'"
    type="link"
    data-booking-block
    :link="ctaHeaderLink?.value"
    :label="isDesktop ? t('header-cta-text-long') : t('header-cta-text-short')"
    :aria-label="isDesktop ? t('header-cta-text-long') : t('header-cta-text-short')"
    :data-cta-text-short="t('header-cta-text-short')"
    :data-cta-text-long="t('header-cta-text-long')"
    data-test-id="header-cta-single-button"
    :tabindex="ctaButtonTabIndex"
    @click="handleBookingClick(isDesktop ? t('header-cta-text-long') : t('header-cta-text-short'))"
  />
  <booking-menu
    v-else-if="bookingOptions.length > 0"
    ref="bookingMenuRef"
    :booking-options="bookingOptions"
    :handle-booking-click="handleBookingClick"
    :cta-button-tab-index="ctaButtonTabIndex"
  />
</template>

<script setup>
import { computed, ref, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import BookingMenu from './BookingMenu/BookingMenu.vue';
import BaseButton from '../2_elements/BaseButton/BaseButton.vue';
import useMedia from '../../utils/useMedia';

const { t } = useI18n();
const { isMedia: isDesktop } = useMedia('(min-width: 1024px)');

const jssStore = inject('jssStore');
const brandName = jssStore?.sitecoreContext()?.brandName;

const props = defineProps({
  ctaHeaderLink: {
    type: Object,
    default: () => ({}),
  },
  ctaButtonTabIndex: {
    type: Number,
  },
  handleBookingClick: {
    type: Function,
  },
  bookingOptions: {
    type: Object,
  },
});

const showCTAButton = computed(() => props.ctaHeaderLink?.value?.href);

const bookingMenuRef = ref(null);

const closeBookingMenu = () => {
  bookingMenuRef.value?.closeMenu();
};

defineExpose({ closeBookingMenu });
</script>
