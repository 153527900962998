<template>
  <section class="iorder-menu">
    <sticky-banner v-bind="props" />
    <div v-if="isLoading" class="iorder-menu__loading">
      <span class="sr-only" role="alert" aria-live="assertive">
        {{ $t('iorder-content-loading') }}
      </span>
      <loading-placeholder :items-number="placeholderItems" modifier-name="iorder" />
    </div>
    <i-order-menu-main
      :fields="props.fields"
      :params="props.params"
      :i-order-menu-rendering="rendering"
      @set-is-loading="setIsLoading"
    />
  </section>
</template>

<script setup>
import { ref, defineAsyncComponent, computed, onMounted, onBeforeUnmount } from 'vue';
import StickyBanner from './StickyBanner/StickyBanner.vue';
import LoadingPlaceholder from '../2_elements/LoadingPlaceholder/LoadingPlaceholder.vue';
import useMedia from '../../utils/useMedia';
import { useAppStore } from '../../stores/appStore';

const IOrderMenuMain = defineAsyncComponent(() =>
  import(/* webpackChunkName: "IOrderMenuMain" */ './IOrderMenuMain.vue')
);

const appStore = useAppStore();

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const isLoading = ref(true);
const { isMedia: isTablet } = useMedia('(min-width: 768px)');
const { isMedia: isDesktop } = useMedia('(min-width: 1024px)');

const placeholderItems = computed(() => {
  if (isDesktop.value) return 9;
  return isTablet.value ? 8 : 3;
});

const setIsLoading = (val) => {
  isLoading.value = val;
};

onBeforeUnmount(() => {
  appStore.disableSmoothScroll(false);
});

onMounted(() => {
  appStore.disableSmoothScroll(true);
});
</script>

<style lang="scss">
@import './scss/IOrderMenu.scss';
</style>
