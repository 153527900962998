<template>
  <div ref="promoMsgContainer" :class="{ sticky: isSticky }" class="fixed-promo-message-container">
    <template v-if="promoMessageItems?.length">
      <message-item
        v-for="(fixedPromoMessageItem, index) in promoMessageItems"
        :key="`fixed-promo-message-${index}`"
        class="fixed-promo-message--desktop"
        :fields="fixedPromoMessageItem"
        @close-msg="closePromoMsg($event)"
      />
    </template>
    <message-item
      v-if="mobileMessageItem"
      class="fixed-promo-message--mobile"
      :fields="mobileMessageItem"
      @close-msg="closePromoMsg($event)"
    />
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, onUpdated, ref, computed, nextTick } from 'vue';
import { useThrottleFn } from '@vueuse/core';
import { useCookies } from '@vueuse/integrations/useCookies';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-vue';
import MessageItem from './FixedPromoMessageItem/FixedPromoMessageItem.vue';
import { useAppStore } from '../../stores/appStore';

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const cookies = !isServer() && useCookies();
const appStore = useAppStore();

const promoMsgContainer = ref(null);
const promoMsgOffset = ref(0);
const isSticky = ref(false);

const promoMessageItems = computed(() =>
  props.fields.promoMessages?.value?.map((item) => ({
    ...item,
    isVisible: cookies ? !cookies.get(`promoMsgClosed_${item.id}`) : true,
  }))
);

const mobileMessageItem = computed(() =>
  props.fields.mobilePromoMessage?.value?.id
    ? {
        ...props.fields.mobilePromoMessage.value,
        isVisible: cookies
          ? !cookies.get(`promoMsgClosed_${props.fields.mobilePromoMessage.value.id}`)
          : true,
      }
    : null
);

const calculatePromoMsgContainerHeight = useThrottleFn(() => {
  if (promoMsgContainer.value && !isEditorActive()) {
    promoMsgOffset.value = promoMsgContainer.value.offsetHeight;
    appStore.setBodyOffset(promoMsgOffset.value);
    isSticky.value = true;
  }
}, 50);

const closePromoMsg = ({ id }) => {
  if (!cookies) return;
  cookies.set(`promoMsgClosed_${id}`, true);

  nextTick(() => {
    calculatePromoMsgContainerHeight();
  });
};

onMounted(() => {
  if (!isEditorActive()) {
    window.addEventListener('resize', onWindowResize);
    calculatePromoMsgContainerHeight();
  }
});

onUpdated(() => {
  calculatePromoMsgContainerHeight();
});

onBeforeUnmount(() => {
  if (!isEditorActive()) {
    window.removeEventListener('resize', onWindowResize);
    appStore.setBodyOffset(0);
  }
});

const onWindowResize = () => {
  (async () => {
    await calculatePromoMsgContainerHeight();
  })();
};
</script>

<style lang="scss">
@import './scss/FixedPromoMessage.scss';
</style>
