import { isServer } from '@sitecore-jss/sitecore-jss';

const ROOT_VALUE = 16;

export const pxToRem = (query) => {
  const regex = new RegExp(/(\d+\.?\d*)px/, 'g');
  return query.replace(regex, (_match, n) => n / ROOT_VALUE + 'rem');
};

export const normalizePx = (value) => {
  if (isServer()) return;

  const htmlEl = document.querySelector('html');
  const fontSize =
    parseFloat(window.getComputedStyle(htmlEl).getPropertyValue('font-size')) || ROOT_VALUE;
  return (fontSize / ROOT_VALUE) * value;
};
